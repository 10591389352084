/*
 * AppProvider
 * @module features/App
 */
import React, { Fragment } from 'react';
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';
import { createGlobalStyle } from 'styled-components/macro';
import reset from 'styled-reset';
import * as R from 'ramda';

import translations from '@/i18n';

R.map(addLocaleData, [en, zh]);

const GlobalStyles = createGlobalStyle`
  ${reset};

  html,body {
    position: relative;
    margin: 0;
    font-family: 'Roboto';
    color: #fff;
    min-width: 1000px;
  }
  .scalable-space {
    height: calc(100vh - 250px)
  }

  #root {
    display: flex;
    width: 100%;
    min-height: 100vh;
    position: relative;
    overflow: auto;
  }

  .rc-tooltip .rc-tooltip-inner {
    min-height: auto;
    padding: 3px 6px;
    border-radius: 2px;
    background-color: #000000;
    color: #fff;
    border: none;
  }

  .rc-tooltip.rc-tooltip-placement-top .rc-tooltip-arrow,
  .rc-tooltip.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
  .rc-tooltip.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    border-top-color: #000;
  }
  .rc-tooltip.rc-tooltip-placement-right .rc-tooltip-arrow,
  .rc-tooltip.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
  .rc-tooltip.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    border-right-color: #000;
  }
  .rc-tooltip.rc-tooltip-placement-left .rc-tooltip-arrow,
  .rc-tooltip.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
  .rc-tooltip.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    border-left-color: #000;
  }
  .rc-tooltip.rc-tooltip-placement-bottom .rc-tooltip-arrow,
  .rc-tooltip.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
  .rc-tooltip.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    border-bottom-color: #000;
  }

  .rc-tooltip.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    right: 18px !important;
  }

  @media only screen and (max-width: 1000px) {
    html,body {
      min-width: auto;
    }
  }
`;

function IntlFragment(props) {
  return props.children || <span {...props} /> || null;
}

/**
 * AppProvider React-component
 * @memberof features/App
 * @param {Object} props - properties
 * @param {React.Children} [props.children] - childs
 * @example <caption>Simple usage</caption>
 * <AppProvider><div /></AppProvider>
 * @returns {React.Node<*>} React-component
 */
export const AppProvider = ({ settings, ...rest }) => {
  const locale = R.propOr('en', 'locale', settings);
  const messages = R.propOr({}, locale, translations);

  return (
    <Fragment>
      <GlobalStyles />
      <IntlProvider
        locale={locale}
        messages={messages}
        textComponent={IntlFragment}
        {...rest}
      />
    </Fragment>
  );
};
