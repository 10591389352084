/*
 * FAQ Messages
 *
 * This contains all the strings for the FAQ component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  // Introduction title
  faq: 'GRC Introduction',
  // Rules title
  rules: 'GRC Rules',
});
