import React, { lazy, Suspense } from 'react';

import Loader from '~/components/Loader';

const Main = lazy(() => import(/* webpackChunkName: "main_page" */ './index'));

const AsyncMain = props => (
  <Suspense fallback={<Loader />}>
    <Main {...props} />
  </Suspense>
);

export default AsyncMain;
