import { loader } from 'graphql.macro';

// Queries
export const ANNOUNCEMENT = loader('./announcementQuery.graphql');
export const ANNOUNCEMENTS_LIST = loader('./announcementsListQuery.graphql');
export const HELPER_MESSAGE = loader('./helperMessageQuery.graphql');
export const INFO = loader('./infoQuery.graphql');
export const LOGGED_IN_USER = loader('./loggedInUserQuery.graphql');
export const MODAL = loader('./modalQuery.graphql');
export const POPULATION_INFO = loader('./populationInfoQuery.graphql');
export const REFERRER_ID = loader('./referral.graphql');
export const ROADMAP_LIST = loader('./roadmapListQuery.graphql');
export const SETTINGS = loader('./settingsQuery.graphql');
export const VIDEO_LIST = loader('./videoListQuery.graphql');

// Mutations
export const ADD_ANNOUNCEMENT_BOOKMARK = loader(
  './addAnnouncementBookmarkMutation.graphql',
);
export const DELETE_ANNOUNCEMENT_BOOKMARK = loader(
  './deleteAnnouncementBookmarkMutation.graphql',
);
export const SAVE_REFERRER_ID = loader('./saveReferrerIdMutation.graphql');
export const CHANGE_MESSAGE = loader('./changeHelperMessageMutation.graphql');
export const MODAL_TOGGLE = loader('./modalMutation.graphql');
export const READ_ANNOUNCEMENTS = loader('./readAnnouncementsMutation.graphql');
export const UPDATE_LOCALE = loader('./changeLocaleMutation.graphql');
