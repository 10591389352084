import { compose, withPropsOnChange, mapProps, pure } from 'recompose';
import * as R from 'ramda';

import ScoreModal from './ScoreModal';

export default compose(
  withPropsOnChange(['message'], ({ message }) => {
    const data = R.tryCatch(JSON.parse, R.always([]))(message);

    return { data };
  }),
  mapProps(R.omit(['message', '__typename'])),
  pure,
)(ScoreModal);
