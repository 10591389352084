import React, { Fragment } from 'react';
import styled from 'styled-components/macro';
import { FormattedDate } from 'react-intl';
import { isMobile } from 'react-device-detect';

import Loader from '~/components/Loader';
import { prepareVideoURI } from '~/helpers';
import announcementPlaceholder from '@/images/announcement.jpg';
import Bookmark from '~/components/Bookmark';
// $FlowIssue
import { ReactComponent as IconPinned } from '@/icons/ic-pin.svg';

const AnnouncementWrapper = styled.div.attrs({
  className: 'react-announcement',
})`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 440px;
  width: 100%;
  cursor: pointer;

  & + & {
    margin-top: 40px;
  }
`;

const AnnouncementMedia = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 12px;
`;

const AnnouncementPlayerLoader = styled.div`
  position: relative;
  z-index: 5;
  width: 100%;
  height: 225px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 450px) {
    height: 209px;
  }

  @media (max-width: 400px) {
    height: 181px;
  }

  @media (max-width: 350px) {
    height: 153px;
  }
`;

const AnnouncementPlayer = styled.iframe.attrs({
  frameBorder: 0,
  title: 'Video Player',
})`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
`;

const AnnouncementImage = styled.img`
  width: 100%;
  height: 100%;
`;

const AnnouncementInformation = styled.div`
  display: flex;
  flex-direction: column;
`;

const AnnouncementDate = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #c7c7c7;
  line-height: 1.29;
`;

const AnnouncementTitle = styled.h3`
  margin: 4px 0 0 !important;
  color: #fff;
`;

const AnnouncementContent = styled.div`
  position: relative;
  margin-top: 8px;
  max-height: ${isMobile ? '72px' : '96px'};
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  overflow: hidden;

  em {
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  ol,
  ul {
    padding-inline-start: 40px;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-padding-start: 40px;
    list-style-position: inside;

    ul {
      list-style-type: circle;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;

const StyledIconPinned = styled(IconPinned)`
  margin-top: 0;
  margin-right: 10px;
`;

/**
 * Announcement React-component
 * @memberof components
 * @param {Object} props - properties
 * @param {string} [props.date] - date
 * @param {string} [props.media] - media
 * @param {string} [props.title] - message title
 * @param {string} [props.description] - message description
 * @param {Function} [props.onOpen] - open handler
 * @example <caption>Simple usage</caption>
 * <Announcement />
 * @returns {React.Node<*>} React-component
 */
const Announcement = ({
  id,
  date,
  media,
  title,
  description,
  onOpen,
  pinned,
  bookmarked,
}) => {
  const imageURI = media || announcementPlaceholder;
  const videoURI = prepareVideoURI(media);
  const isMediaAvailable = !!(imageURI || videoURI);

  return (
    <AnnouncementWrapper>
      {isMediaAvailable && (
        <AnnouncementMedia onClick={onOpen}>
          {videoURI ? (
            <Fragment>
              <AnnouncementPlayerLoader>
                <Loader />
              </AnnouncementPlayerLoader>
              <AnnouncementPlayer src={videoURI} />
            </Fragment>
          ) : (
            <AnnouncementImage src={imageURI} />
          )}
        </AnnouncementMedia>
      )}
      <AnnouncementInformation>
        <AnnouncementDate>
          <FormattedDate
            value={date}
            year="numeric"
            month="short"
            day="numeric"
          />
          {pinned ? (
            <StyledIconPinned />
          ) : (
            <Bookmark active={bookmarked} id={id} />
          )}
        </AnnouncementDate>
        <AnnouncementTitle onClick={onOpen}>{title}</AnnouncementTitle>
        <AnnouncementContent
          onClick={onOpen}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </AnnouncementInformation>
    </AnnouncementWrapper>
  );
};

export default Announcement;
