/*
 * Messages
 *
 * This contains all the platform names.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  // Jemjar platform
  Jemjar: 'Jemjar',
  // Powerkingdoms platform
  Powerkingdoms: 'Powerkingdoms',
  // BTC Boosters platform
  BTCBoosters: 'BTC Boosters',
  // BTC Boosters platform
  BTCBoostersInternational: 'EN.BTCBoosters.com (International)',
  // Wenda platform
  Wenda: 'Wenda',
  // Renwu platform
  Renwu: 'okRenwu',
});
