import {
  compose,
  withStateHandlers,
  withHandlers,
  lifecycle,
  pure,
} from 'recompose';
import uuid from 'uuid/v4';

import Ellipsis from './Ellipsis';

const enhance = compose(
  withHandlers(() => {
    let ref;

    return {
      setRef: () => value => {
        ref = value;
      },
      getElementWidth: () => () => {
        if (ref) {
          return ref.parentNode.offsetWidth;
        }

        return null;
      },
    };
  }),
  withStateHandlers(({ width }) => ({ id: undefined, width }), {
    setId: state => id => ({ ...state, id }),
    getWidth: (state, { getElementWidth }) => () => ({
      ...state,
      width: getElementWidth(),
    }),
  }),
  lifecycle({
    componentDidMount() {
      this.props.setId(uuid());

      if (!this.props.width) {
        setTimeout(this.props.getWidth, 250);
      }
    },
  }),
  pure,
);

export default enhance(Ellipsis);
