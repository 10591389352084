import React from 'react';
import styled, { css } from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';
import * as R from 'ramda';

import Announcement from './components/Announcement';
import Modal from '~/components/Modal';
import Form from '~/components/Form';

import messages from './messages';

const AnnouncementModalOuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: calc(100vh - 90px);
`;

const AnnouncementModalInnerContainer = styled.div`
  ${isMobile
    ? css`
        width: 100%;
      `
    : ''};

  & > div {
    width: ${isMobile ? '100%' : '490px'};
  }

  h4 {
    padding: 0 10px 10px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #5dc6f6;
    text-transform: uppercase;
    text-align: left;
  }
`;

const AnnouncementModalBody = styled.div`
  margin-top: 5px;
  width: 100%;
  max-height: ${isMobile ? '500px' : '686px'};
  overflow-y: auto;
  text-align: left;

  @media (max-height: 760px) {
    max-height: 300px;
  }
`;

/**
 * AnnouncementModal React-component
 * @memberof components
 * @param {Object} props - properties
 * @param {Array} [props.announcementsList] - list of announcements
 * @param {Function} [props.onClose] - close handler
 * @param {Function} [props.onOpenAnnouncement] - open announcement handler
 * @example <caption>Simple usage</caption>
 * <AnnouncementModal />
 * @returns {React.Node<*>} React-component
 */
const AnnouncementModal = ({
  announcementsList,
  onClose,
  onOpenAnnouncement,
}) => {
  const Wrapper = ({ CloseModal }) => {
    return (
      <AnnouncementModalOuterContainer>
        <AnnouncementModalInnerContainer>
          <Form>
            <CloseModal />
            <FormattedMessage {...messages.title} tagName="h4" />
            <AnnouncementModalBody>
              {R.map(
                ({ id, ...rest }) => (
                  <Announcement
                    key={id}
                    id={id}
                    onOpen={() => onOpenAnnouncement(id)}
                    {...rest}
                  />
                ),
                announcementsList,
              )}
            </AnnouncementModalBody>
          </Form>
        </AnnouncementModalInnerContainer>
      </AnnouncementModalOuterContainer>
    );
  };

  return !R.isEmpty(announcementsList) ? (
    <Modal onClose={onClose} isBlurred>
      <Wrapper />
    </Modal>
  ) : null;
};

export default AnnouncementModal;

AnnouncementModal.defaultProps = {
  announcementsList: [],
};
