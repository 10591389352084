/*
 * AnnouncementModal
 *
 * This contains all the strings for the AnnouncementModal component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  // Popup title
  title: 'Announcement',
});
