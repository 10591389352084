import { compose, pure } from 'recompose';
import { graphql } from 'react-apollo';

import { withDevice } from '~/features/App';
import { MODAL_TOGGLE, ROADMAP_LIST } from '~/gql';

import Roadmap from './Roadmap';

const withRoadmapList = graphql(ROADMAP_LIST, {
  alias: 'withAnnouncementsList',
  options: {
    fetchPolicy: 'cache-and-network',
  },
  props: ({ data }) => data,
});

const withModal = graphql(MODAL_TOGGLE, {
  name: 'modal',
});

export default compose(
  withDevice,
  withRoadmapList,
  withModal,
  pure,
)(Roadmap);
