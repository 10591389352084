/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { ApolloService } from '../services';
import { INFO } from '~/gql';

(function(w, d, n, a, j) {
  w[n] =
    w[n] ||
    function() {
      (w[n].a = w[n].a || []).push(arguments);
    };
  j = d.createElement('script');
  j.async = true;
  j.src = 'https://qiyukf.com/script/227745243b9c8f4fd3b377d095b68ae5.js';
  d.body.appendChild(j);
})(window, document, 'ysf');

function newguid() {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  function S() {
    var _s = new Date().getTime().toString(16);
    for (var i = _s.length; i < 12; i++) {
      _s += Math.floor(Math.random() * 10);
    }
    return _s.substring(0, 12);
  }
  return S4() + S4() + '' + S4() + '' + S4() + '' + S4() + '' + S();
}

async function getUserData() {
  try {
    const {
      data: {
        info: { guid, username },
      },
    } = await ApolloService.client.query({ query: INFO });

    return {
      uid: guid,
      name: username,
    };
  } catch (e) {
    return {};
  }
}

export function initYsf(isLoggedIn) {
  window['ysf']('onready', () => {
    if (isLoggedIn) {
      getUserData().then(({ uid }) => {
        if (typeof uid === 'undefined') {
          setTimeout(initYsf, 500);
        } else {
          window['ysf']('config', {
            uid,
            name: uid,
            level: 1,
            success: function() {
              console.log(`uid: ${uid}`);
            },
            error: function(e) {
              console.log('Error while init ysf', e);
            },
          });
        }
      });
    } else {
      let uid = newguid();
      window['ysf']('config', {
        uid,
        name: `guest${uid}`,
        level: 1,
        success: function() {
          console.log(`uid: ${uid}`);
        },
        error: function(e) {
          console.log('Error while init ysf', e);
        },
      });
    }

    // move live agent
    var timer = setInterval(() => {
      var chatButton = document.getElementById('YSF-BTN-HOLDER');

      if (chatButton) {
        clearInterval(timer);
        initMoveAgent(chatButton);
      }
    }, 500);

    function initMoveAgent(chatButton) {
      // custom code
      function getBordersPosition() {
        return {
          x1: -8,
          x2: (window.innerWidth || document.body.clientWidth) - 64,
          y1: -8,
          y2: (window.innerHeight || document.body.clientHeight) - 64,
        };
      }

      function setInitialPosition() {
        if (liveAgentButton) {
          clearInterval(init);
        }

        liveAgentButton.style.touchAction = 'none';

        try {
          var liveAgent = JSON.parse(localStorage.getItem(LIVE_AGENT_KEY));

          if (liveAgent) {
            setPosition(liveAgent.left, liveAgent.top, liveAgentButton);
          } else {
            var bordersPosition = getBordersPosition();

            setPosition(
              bordersPosition.x1 + 40,
              bordersPosition.y2 - 40,
              liveAgentButton,
            );
          }
        } catch (e) {}
      }

      function setPosition(x, y, element) {
        var bordersPosition = getBordersPosition();
        var top = y
          ? Math.max(bordersPosition.y1, Math.min(y, bordersPosition.y2))
          : bordersPosition.y2;
        var left = x
          ? Math.max(bordersPosition.x1, Math.min(x, bordersPosition.x2))
          : bordersPosition.x1;

        element.style.marginTop = 0;
        element.style.top = top + 'px';
        element.style.left = left + 'px';
        element.style.right = 'auto';
        element.style.bottom = 'auto';

        localStorage.setItem(
          LIVE_AGENT_KEY,
          JSON.stringify({
            top: top,
            left: left,
          }),
        );
      }

      function handler(event) {
        event.preventDefault();
        function unsubscribe(e) {
          e.preventDefault();

          if (event.touches && isTouched()) {
            moved = false;
          }

          if (!moved) {
            onLiveButtonClick.apply(chatButton);
          }

          moved = false;
          window.removeEventListener('mousemove', repositionElement);
          window.removeEventListener('touchmove', repositionElement);
          window.removeEventListener('mouseup', unsubscribe, true);
          window.removeEventListener('touchend', unsubscribe, true);
          chatButton.removeEventListener('mouseout', unsubscribe);

          function isTouched() {
            var TOUCH_DURATION = 100;
            var touchEndTime = Date.now();
            var touchTime = touchEndTime - touchStartTime;

            return touchTime < TOUCH_DURATION;
          }
        }

        function repositionElement(event) {
          var clientX =
            event.clientX || (event.touches && event.touches[0].clientX);
          var clientY =
            event.clientY || (event.touches && event.touches[0].clientY);

          moved = true;
          setPosition(clientX + diffX, clientY + diffY, chatButton);
        }

        var rect = chatButton.getBoundingClientRect();

        touchStartTime = Date.now();
        moved = false;
        initX = rect.left;
        initY = rect.top;

        mousePressX =
          event.clientX || (event.touches && event.touches[0].clientX);
        mousePressY =
          event.clientY || (event.touches && event.touches[0].clientY);

        var diffX = initX - mousePressX;
        var diffY = initY - mousePressY;

        window.addEventListener('mousemove', repositionElement);
        window.addEventListener('touchmove', repositionElement);
        window.addEventListener('mouseup', unsubscribe, true);
        window.addEventListener('touchend', unsubscribe, true);
      }

      var LIVE_AGENT_KEY = 'MS:LIVE_AGENT';
      var init = setInterval(setInitialPosition, 50);
      var liveAgentButton = chatButton;
      var onLiveButtonClick = chatButton.onclick;
      var initX;
      var initY;
      var moved;
      var mousePressX;
      var mousePressY;
      var touchStartTime;

      chatButton.onclick = function() {};
      chatButton.addEventListener('mousedown', handler, false);
      chatButton.addEventListener('touchstart', handler, false);
      window.addEventListener('resize', setInitialPosition);
      // end custom code
    }
  });
}
