import {
  compose,
  branch,
  withStateHandlers,
  renderNothing,
  lifecycle,
  mapProps,
} from 'recompose';
import { isMobile, isTablet } from 'react-device-detect';

const getOrientation = (window = {}) =>
  window.orientation === 90 ? 'landscape' : 'portrait';

const getDevice = () => {
  let device = 'desktop';

  if (isTablet) {
    device = 'tablet';
  } else if (isMobile) {
    device = 'mobile';
  }

  return device;
};

export const withDevice = compose(
  withStateHandlers(
    { device: getDevice(), orientation: getOrientation(window) },
    {
      onChangeOrientation: state => e => {
        e && e.preventDefault();

        return { ...state, orientation: getOrientation(e.target) };
      },
    },
  ),
  lifecycle({
    componentDidMount() {
      window.addEventListener(
        'orientationchange',
        this.props.onChangeOrientation,
      );
    },
    componentWillUnmount() {
      window.removeEventListener(
        'orientationchange',
        this.props.onChangeOrientation,
      );
    },
  }),
  mapProps(({ onChangeOrientation, ...rest }) => ({
    ...rest,
  })),
);

export const Device = compose(
  withDevice,
  branch(({ children }) => !children, renderNothing),
)(({ children, ...rest }) => children(rest));
