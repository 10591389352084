import * as R from 'ramda';
import messages from './messages';

export const mapPlatformListIntl = (data = []) =>
  R.map(item => {
    const name = item['platformName'];
    let intl;

    // used "for in" loop for breaking looping through huge amount of data
    for (let k in messages) {
      if (name === messages[k].defaultMessage) {
        intl = messages[k];
        break;
      }
    }

    if (!intl) {
      intl = name || '';
    }

    return { ...item, intl };
  }, data);
