import * as R from 'ramda';

import { HttpService, AceService } from '~/services';
import { renameKeys, parseBool } from '~/helpers';

import { ANNOUNCEMENTS_LIST, ANNOUNCEMENT } from '~/gql';

async function setToken() {
  const { accessToken } = await AceService.getTokens();
  HttpService.setAuthorizationToken(accessToken);
}

const getAnnouncement = R.pipe(
  renameKeys({
    Id: 'id',
    PublishedDateTimeUtc: 'date',
    Title: 'title',
    Content1: 'content',
    ShortDesc: 'description',
    Content2: 'media',
    Pin: 'pinned',
    Bookmark: 'bookmarked',
  }),
  R.evolve({
    pinned: parseBool,
    bookmarked: parseBool,
  }),
  R.assoc('__typename', 'AnnouncementsListItem'),
);

export const resolvers = {
  Query: {
    announcementsList: async (_, { offset, limit, onlyNew }, { cache }) => {
      await setToken();

      const getGrcAnnouncement = ({ url }) => {
        return HttpService.client.get(url, {
          params: {
            SortBy: 'PublishedDateTimeUtc',
            PageSize: limit,
            PageNum: Math.floor(offset / limit) + 1,
          },
        });
      };

      const {
        data: { GrcAnnouncementLine, GRCNewAnnouncementList, RecordCount },
      } = await getGrcAnnouncement({
        url: `/Information/GrcLanding/${
          onlyNew ? 'GetNewAnnouncementList' : 'GetAnnouncementList'
        }`,
      });

      const items = R.map(
        getAnnouncement,
        GrcAnnouncementLine || GRCNewAnnouncementList,
      );

      const announcementsList = {
        items,
        total: RecordCount || 0,
        __typename: 'AnnouncementsList',
      };

      cache.writeQuery({
        query: ANNOUNCEMENTS_LIST,
        data: { announcementsList },
      });

      return announcementsList;
    },

    announcement: async (_, { id }, { cache }) => {
      await setToken();
      const {
        data: { GrcAnnouncementDetail },
      } = await HttpService.client.get(
        '/Information/GrcLanding/GetAnnouncementContent',
        {
          params: { Id: id },
        },
      );

      const announcement = R.pipe(
        R.head,
        getAnnouncement,
        R.assoc('id', id),
      )(GrcAnnouncementDetail);

      cache.writeData({
        data: { announcement },
        variables: { id },
      });

      return announcement;
    },
  },
  Mutation: {
    addAnnouncementBookmark: async (_, { id }, { cache }) => {
      await setToken();

      R.tryCatch(
        () => {
          const { announcement } = cache.readQuery({
            query: ANNOUNCEMENT,
            variables: { id },
          });

          cache.writeData({
            data: {
              announcement: {
                ...announcement,
                ...{ bookmarked: true },
              },
            },
            variables: { id },
          });
        },
        () => {
          const { announcementsList } = cache.readQuery({
            query: ANNOUNCEMENTS_LIST,
          });

          cache.writeData({
            data: {
              announcementsList: {
                ...announcementsList,
                ...{
                  items: R.compose(
                    R.map(
                      R.when(R.propEq('id', id), R.assoc('bookmarked', true)),
                    ),
                  )(announcementsList.items),
                },
              },
            },
          });
        },
      )();

      await HttpService.client.post(
        '/Information/GrcLanding/AddBookmarkGrcAnnouncement',
        {
          id: [`${id}`],
        },
      );

      return { code: 0, message: 'Done', __typename: 'MessagePayload' };
    },

    deleteAnnouncementBookmark: async (_, { id }, { cache }) => {
      await setToken();

      R.tryCatch(
        () => {
          const { announcement } = cache.readQuery({
            query: ANNOUNCEMENT,
            variables: { id },
          });
          cache.writeData({
            data: {
              announcement: { ...announcement, ...{ bookmarked: false } },
              variables: { id },
            },
          });
        },
        () => {
          const { announcementsList } = cache.readQuery({
            query: ANNOUNCEMENTS_LIST,
          });
          const updateItems = R.compose(
            R.map(R.when(R.propEq('id', id), R.assoc('bookmarked', false))),
          );
          cache.writeData({
            data: {
              announcementsList: {
                ...announcementsList,
                ...{ items: updateItems(announcementsList.items) },
              },
            },
          });
        },
      )();

      await HttpService.client.post(
        '/Information/GrcLanding/DeleteBookmarkGrcAnnouncement',
        {
          id: [`${id}`],
        },
      );

      return { code: 0, message: 'Done', __typename: 'MessagePayload' };
    },

    readAnnouncementsMutation: async (_, { ids }) => {
      await setToken();

      await HttpService.client.post(
        '/Information/GrcLanding/AnnouncementIsRead',
        { Id: ids },
      );

      return { code: 0, message: 'Done', __typename: 'MessagePayload' };
    },
  },
};
