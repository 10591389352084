import axios from 'axios';
import * as R from 'ramda';
import { AceService } from '~/services';

export { CancelToken } from 'axios';

export const client = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT || ''}`,
});

export const setAuthorizationToken = token => {
  client.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const removeAuthorizationToken = () => {
  delete client.defaults.headers.common.Authorization;
};

export const setAcceptLanguage = locale => {
  client.defaults.headers.common['Accept-Language'] = locale;
};

const userUnauthorizedCallback = response => {
  const {
    data: { ReturnCode, ReturnMessage },
    status,
    config: { url },
  } = response;

  const isUnauthorizedStatus = status === 401;

  if (
    (ReturnCode || isUnauthorizedStatus) &&
    !R.includes('/Account/Logout', url)
  ) {
    if (ReturnCode === 1008 || isUnauthorizedStatus) {
      AceService.authorize();
    } else {
      throw new Error(ReturnMessage);
    }
  }

  return response;
};

// Add a response interceptor
client.interceptors.response.use(userUnauthorizedCallback, error => {
  userUnauthorizedCallback(error.response);

  Promise.reject(error);
});
