import React from 'react';
import styled from 'styled-components/macro';

import Table from '~/components/Table';

const StyledWrapper = styled.div`
  & + & {
    margin-left: 14px;
  }

  .ReactTable {
    border: 0;

    .rt-th {
      font-size: 12px;
      color: #e4e4e4;
    }

    .rt-td {
      font-size: 14px;
    }

    .rt-thead,
    .rt-tbody {
      padding: 0;
      box-sizing: border-box;
    }

    .rt-th,
    .rt-td {
      padding: 5px;
      text-align: left;

      &:last-child {
        text-align: right;
      }
    }

    .rt-tr {
      padding: 0 22px;

      &.-active {
        border: solid 1px #ffd951;
        color: #ffd951;
      }
    }

    .rt-tbody .rt-tr-group:nth-child(2n-1) {
      background-color: #03244e;
    }
  }
`;

const Score = props => (
  <StyledWrapper>
    <Table
      resizable={false}
      minRows={0}
      showPagination={false}
      sortable={false}
      showPageSizeOptions={false}
      {...props}
    />
  </StyledWrapper>
);

export default Score;
