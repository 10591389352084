import { compose, branch, renderNothing, withStateHandlers } from 'recompose';

export const withVisibility = withStateHandlers(
  { isVisible: false },
  {
    setVisible: () => () => ({ isVisible: true }),
    setInvisible: () => () => ({ isVisible: false }),
  },
);

export const Visibility = compose(
  withVisibility,
  branch(({ children }) => !children, renderNothing),
)(({ children, ...rest }) => children(rest));
