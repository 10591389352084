import React from 'react';
import ReactDOM from 'react-dom';
import Raven from 'raven-js';

import App from '~/App';
import { AceService } from '~/services';

const renderApp = () =>
  ReactDOM.render(<App />, document.getElementById('root'));

const startApp = () => {
  if (process.env.NODE_ENV === 'production') {
    Raven.config(process.env.REACT_APP_LOGGER_ENDPOINT, {
      release: process.env.REACT_APP_VERSION,
    }).install();
    Raven.context(renderApp);
  } else {
    renderApp();
  }
};

AceService.getTokens().then(() => startApp());
