import {
  pure,
  compose,
  mapProps,
  withProps,
  withPropsOnChange,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import * as R from 'ramda';

import { ANNOUNCEMENTS_LIST, READ_ANNOUNCEMENTS } from '~/gql';
import { openInNewTab } from '~/helpers';

import AnnouncementModal from './AnnouncementModal';

export const withReadAnnouncementsMutation = graphql(READ_ANNOUNCEMENTS, {
  name: 'readAnnouncements',
});

let isNewAnnouncementFetchedOnIndexPage;

export default compose(
  withReadAnnouncementsMutation,
  withRouter,
  withPropsOnChange(
    ['message', 'onClose'],
    ({ message, onClose, readAnnouncements, location }) => {
      const announcementsList = R.tryCatch(JSON.parse, R.F)(message);

      if (R.isEmpty(announcementsList)) {
        return {};
      }

      if (location.pathname === '/') {
        isNewAnnouncementFetchedOnIndexPage = true;
      }

      return {
        announcementsList,
        markAllAnnouncementsAsRead: () => {
          onClose();
          if (isNewAnnouncementFetchedOnIndexPage) {
            readAnnouncements({
              variables: { ids: R.map(({ id }) => id, announcementsList) },
            });
          } else {
            readAnnouncements({
              variables: { ids: R.map(({ id }) => id, announcementsList) },
              refetchQueries: [
                {
                  query: ANNOUNCEMENTS_LIST,
                  variables: { offset: 0, limit: 1, onlyNew: true },
                },
              ],
            });
          }
        },
      };
    },
  ),
  withProps(({ markAllAnnouncementsAsRead }) => ({
    onClose: markAllAnnouncementsAsRead,
    onOpenAnnouncement: id => {
      openInNewTab(`/announcements/${id}`);
      markAllAnnouncementsAsRead();
    },
  })),
  mapProps(R.omit(['message'])),
  pure,
)(AnnouncementModal);
