import React from 'react';
import styled, { css } from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';

import Modal from '~/components/Modal';
import Form from '~/components/Form';
import Ellipsis from '~/components/Ellipsis';
import DataValue from '~/components/DataValue';

import Score from '~/components/Score';

import messages from './messages';

const ScoreModalExtendedMobileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: calc(100vh - 90px);
`;

const ScoreModalExtendedFormWrapper = styled.div`
  display: flex;
  width: ${isMobile ? 'auto' : '1200px'};
  max-width: ${isMobile ? 'calc(100% - 5px)' : '1200px'};
  max-height: 100%;

  & > div {
    width: 100%;
    flex: 1;
  }

  h4 {
    padding: 16px 10px 10px 37px;
    text-align: left;

    ${isMobile
      ? css`
          font-size: 20px;
          font-weight: bold;
          line-height: 24px;
        `
      : css`
          font-size: 24px;
          font-weight: 500;
          line-height: 28px;
        `};
  }

  .react-form-content {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    box-sizing: border-box;
  }
`;

const ScoreModalExtendedRowWrapper = styled.div`
  display: flex;
  flex-direction: ${isMobile ? 'column' : 'row'};
  overflow: ${isMobile ? 'auto' : 'hidden'};
`;

const ScoreModalExtendedRowItem = styled.div`
  width: ${isMobile ? '555px' : 'auto'};
  && {
    .rt-th {
      white-space: pre-wrap;
      &:last-child {
        text-align: left;
      }
    }
    .rt-td {
      &:last-child {
        text-align: left;
      }
    }
  }
`;

const ScoreModalExtendedFormContent = styled.div`
  display: flex;
  margin: 5px 10px;
  text-align: left;
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
`;

const ScoreModalExtended = ({
  onClose,
  data: { previousWinnersList, currentWinnersList },
}) => {
  const secondColWidth = 150;
  const thirdColWidth = isMobile ? 115 : 110;
  const fourthColWidth = 95;
  const fifthColWidth = 87;

  const previousColumns = [
    {
      Header: <FormattedMessage {...messages.ranking} />,
      accessor: 'ranking',
      width: isMobile ? 50 : 75,
    },
    {
      Header: <FormattedMessage {...messages.merchantName} />,
      accessor: 'merchantName',
      Cell: ({ value }) =>
        typeof value === 'string' ? value : <FormattedMessage {...value} />,
      width: secondColWidth,
    },
    {
      Header: <FormattedMessage {...messages.platformName} />,
      accessor: 'intl',
      Cell: ({ value }) => (
        <Ellipsis width={thirdColWidth - 15}>
          {!value || typeof value === 'string' ? (
            value
          ) : (
            <FormattedMessage {...value} />
          )}
        </Ellipsis>
      ),
      width: thirdColWidth,
    },
    {
      Header: <FormattedMessage {...messages.btcBoucherAmount} />,
      accessor: 'voucherMonthValue',
      Cell: ({ value }) =>
        value ? <DataValue width={fourthColWidth - 15} value={value} /> : '',
      width: fourthColWidth,
    },
    {
      Header: <FormattedMessage {...messages.mBtcRewardAmount} />,
      accessor: 'rewardMonthValue',
      Cell: ({ value }) =>
        value ? <DataValue width={fifthColWidth - 15} value={value} /> : '',
      width: fifthColWidth,
    },
  ];

  const newStarsColumns = [
    {
      Header: <FormattedMessage {...messages.ranking} />,
      accessor: 'ranking',
      width: isMobile ? 50 : 85,
    },
    {
      Header: <FormattedMessage {...messages.merchantName} />,
      accessor: 'merchantName',
      Cell: ({ value }) =>
        typeof value === 'string' ? value : <FormattedMessage {...value} />,
      width: secondColWidth,
    },
    {
      Header: <FormattedMessage {...messages.platformName} />,
      accessor: 'intl',
      Cell: ({ value }) => (
        <Ellipsis width={thirdColWidth - 15}>
          {!value || typeof value === 'string' ? (
            value
          ) : (
            <FormattedMessage {...value} />
          )}
        </Ellipsis>
      ),
      width: thirdColWidth,
    },
    {
      Header: <FormattedMessage {...messages.btcBoucherAmount} />,
      accessor: 'monthValue',
      Cell: ({ value }) =>
        value ? <DataValue width={fourthColWidth - 15} value={value} /> : '',
      width: fourthColWidth,
    },
  ];

  const getTrProps = (_, { original: { myScore } }) =>
    myScore ? { className: '-active' } : {};

  const Wrapper = ({ CloseModal }) => {
    return (
      <Modal onClose={isMobile && onClose} isBlurred>
        <ScoreModalExtendedMobileContainer>
          <ScoreModalExtendedFormWrapper>
            <Form>
              <CloseModal />
              <ScoreModalExtendedRowWrapper>
                <ScoreModalExtendedRowItem>
                  <FormattedMessage
                    {...messages.previousWinnersList}
                    tagName="h4"
                  />

                  <ScoreModalExtendedFormContent>
                    <Score
                      data={previousWinnersList}
                      pageSize={previousWinnersList.length}
                      columns={previousColumns}
                      getTrProps={getTrProps}
                    />
                  </ScoreModalExtendedFormContent>
                </ScoreModalExtendedRowItem>
                <ScoreModalExtendedRowItem>
                  <FormattedMessage
                    {...messages.currentWinnersList}
                    tagName="h4"
                  />
                  <ScoreModalExtendedFormContent>
                    <Score
                      data={currentWinnersList}
                      pageSize={currentWinnersList.length}
                      columns={newStarsColumns}
                      getTrProps={getTrProps}
                    />
                  </ScoreModalExtendedFormContent>
                </ScoreModalExtendedRowItem>
              </ScoreModalExtendedRowWrapper>
            </Form>
          </ScoreModalExtendedFormWrapper>
        </ScoreModalExtendedMobileContainer>
      </Modal>
    );
  };

  return (
    <Modal onClose={isMobile && onClose} isBlurred>
      <Wrapper />
    </Modal>
  );
};

ScoreModalExtended.defaultProps = {
  data: {},
};

export default ScoreModalExtended;
