import { graphql } from 'react-apollo';
import { compose } from 'recompose';

import { MODAL } from '~/gql';

import Modals from './Modals';

const withModal = graphql(MODAL, {
  alias: 'withModal',
  props: ({ data }) => data,
});

export default compose(withModal)(Modals);
