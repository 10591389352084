import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import { isMobile } from 'react-device-detect';

import titleLeft from '@/icons/title_board_l.svg';
import titleRight from '@/icons/title_board_r.svg';
import titleCenter from '@/icons/title_board_c.svg';
import outerEdgeLeft from '@/icons/outer_edge_l.svg';
import outerEdgeRight from '@/icons/outer_edge_r.svg';
import contentBorderBottomLeft from '@/icons/luminous-BG_bottom-left.svg';
import contentBorderBottomRight from '@/icons/luminous-BG_bottom-right.svg';
import contentBorderTopLeft from '@/icons/luminous-BG_top-left.svg';
import contentBorderTopRight from '@/icons/luminous-BG_top-right.svg';
import contentVerticalCenter from '@/icons/luminous-BG_centr-horisontal.svg';
import contentHorizontalCenter from '@/icons/luminous-BG_centr-vertical.svg';

const StyledWrapper = styled.div`
  display: inline-block;
  position: relative;
  min-width: ${ifProp('isMobile', '150px', '220px')};
  color: #fff;
  width: ${ifProp('twoRows', '220px', 'auto')};

  &:before,
  &:after {
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background: url(${outerEdgeLeft}) 0 0 no-repeat;

    content: '';
  }

  &:after {
    left: auto;
    right: 0;
    background: url(${outerEdgeRight}) 0 0 no-repeat;
  }
`;

const StyledLeftBorder = styled.div`
  position: absolute;
  width: 1px;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #58b5e0;
`;

const StyledTopBorder = styled.div`
  position: absolute;
  height: 1px;
  top: 0;
  right: 0;
  left: 0;
  background-color: #58b5e0;
`;

const StyledRightBorder = styled.div`
  position: absolute;
  width: 1px;
  top: 0;
  right: 0;
  bottom: 24px;
  background-color: #58b5e0;
`;

const StyledBottomBorder = styled.div`
  position: absolute;
  height: 1px;
  bottom: 0;
  left: 24px;
  right: 24px;
  background-color: #58b5e0;
`;

const StyledHeader = styled.div`
  height: 26px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-around;
  font-family: Roboto;
  font-size: 15px;
  padding: 0 20px;
  text-align: center;
  background: url(${titleLeft}) 0 0 no-repeat,
    url(${titleRight}) 100% 0 no-repeat;

  ${ifProp(
    'twoRows',
    css`
      font-size: 12px;
      padding: 0 20px;
      text-align: center;
      word-break: break-word;
    `,
    '',
  )};

  &:before {
    position: absolute;
    left: 19px;
    right: 19px;
    height: 100%;
    content: '';
    background: url(${titleCenter}) 0 0 repeat-x;
  }
`;

const StyledContent = styled.div`
  position: relative;
  margin: 4px;
  padding: 0 5px 10px;
  height: calc(100% - 18px);
  z-index: 1;

  &:before {
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 10px;
    top: 10px;
    content: '';
    z-index: -1;
    background-color: #043267;
    opacity: 0.8;
  }
`;

const StyledContentLeftBorder = styled.div`
  position: absolute;
  width: 10px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: url(${contentBorderTopLeft}) 0 0 no-repeat,
    url(${contentBorderBottomLeft}) 0 100% no-repeat;

  &:before {
    display: block;
    position: absolute;
    bottom: 10px;
    top: 10px;
    left: 0;
    right: 0;
    content: '';
    background: url(${contentVerticalCenter}) 0 10px;
  }
`;

const StyledContentRightBorder = styled.div`
  position: absolute;
  width: 10px;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background: url(${contentBorderTopRight}) 0 0 no-repeat,
    url(${contentBorderBottomRight}) 0 100% no-repeat;

  &:before {
    display: block;
    position: absolute;
    bottom: 10px;
    top: 10px;
    left: 0;
    right: 0;
    content: '';
    transform: rotate(180deg);
    background: url(${contentVerticalCenter}) 0 10px;
  }
`;

const StyledContentTopBorder = styled.div`
  position: absolute;
  height: 10px;
  left: 10px;
  right: 10px;
  top: 0;
  z-index: -1;
  background: url(${contentHorizontalCenter}) 0 0;
`;

const StyledContentBottomBorder = styled.div`
  position: absolute;
  height: 10px;
  left: 10px;
  bottom: 0;
  right: 10px;
  z-index: -1;
  transform: rotate(180deg);
  background: url(${contentHorizontalCenter}) 0 0;
`;

const StyledValue = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const StyledDescription = styled.div`
  font-size: 15px;
  color: #e4e4e4;
  text-align: center;
  margin-bottom: 7px;
`;

const StyledItems = styled.div.attrs({
  className: 'react-form-content',
})`
  padding: 15px 0 8px;
  text-align: center;
`;

export default ({ title, children, value, description, twoRows, ...rest }) => (
  <StyledWrapper {...rest} twoRows={twoRows} isMobile={isMobile}>
    <StyledRightBorder />
    <StyledBottomBorder />
    <StyledLeftBorder />
    <StyledTopBorder />
    <StyledContent>
      <StyledContentLeftBorder />
      <StyledContentRightBorder />
      <StyledContentTopBorder />
      <StyledContentBottomBorder />
      {title && <StyledHeader twoRows={twoRows}>{title}</StyledHeader>}
      <StyledItems>
        {description && <StyledDescription>{description}</StyledDescription>}
        {!!value && <StyledValue>{value}</StyledValue>}
        {children}
      </StyledItems>
    </StyledContent>
  </StyledWrapper>
);
