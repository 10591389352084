/*
 * ScoreModal
 *
 * This contains all the strings for the ScoreModal component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  // Task Reward Ranking headliner
  taskRewardRankingList: 'Task Reward Ranking',
  // BTC Voucher Ranking headliner
  btcVoucherRankingList: 'Voucher Total Purchase Ranking',
  // Merchants Ranking headliner
  merchantRankingList: 'Merchants Ranking',
  // Field name
  ranking: 'Ranking',
  // Field name
  cityPeople: 'Citizen',
  // Field name
  orderQuantity: 'Self Score',
  // Field name
  totalVoucherPurchase: 'Total Voucher Purchase',
  // Field name
  platformName: 'Platform name',
  // Field name
  amount: 'Total BTC-Voucher',
});
