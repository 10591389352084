import { HttpService } from '~/services';
import { renameKeys } from '~/helpers';
import * as R from 'ramda';

const processExternalVideos = video => ({
  ...video,
  externalVideos: R.map(
    R.pipe(
      renameKeys({
        Src: 'src',
      }),
      R.assoc('__typename', 'VideoListItemExternalLink'),
    ),
    video.ExternalVideos,
  ),
});

export const resolvers = {
  Query: {
    videoList: async (
      // $FlowIssue
      _, // eslint-disable-line no-unused-vars
      { pageNum = 1 },
      { cache },
    ) => {
      const {
        data: { GrcLandingVideoList, PageNum = 1, PageCount },
      } = await HttpService.client.get('/Information/GrcLanding/GetVideoList', {
        params: {
          PageNum: pageNum,
          PageSize: 100,
        },
      });

      const items = R.map(
        R.pipe(
          processExternalVideos,
          renameKeys({
            Id: 'id',
            Title: 'title',
            ImageSrc: 'imageSrc',
            VideoSrc: 'videoSrc',
            PublishedDateTimeUtc: 'date',
            ExternalVideos: 'externalVideos',
          }),
          R.assoc('__typename', 'VideoListItem'),
        ),
        GrcLandingVideoList,
      );

      const videoList = {
        items,
        pageNum: PageNum,
        pageCount: PageCount,
        __typename: 'VideoList',
      };

      cache.writeData({ data: { videoList }, variables: { pageNum } });

      return videoList;
    },
  },
};
