/* eslint-disable no-fallthrough */
import React from 'react';
import { Mutation } from 'react-apollo';

import ClientAppModal from '~/components/ClientAppsModal';
import AnnouncementModal from '~/components/AnnouncementModal';
import RoadmapItemModal from '~/components/RoadmapItemModal';
import ScoreModal from '~/components/ScoreModal';
import ScoreModalExtended from '~/components/ScoreModalExtended';

import { MODAL_TOGGLE } from '~/gql';

export default ({ modal }) => {
  const name = modal && modal.name;
  let Component;

  switch (name) {
    case 'clientapp':
      Component = ClientAppModal;
      break;
    case 'announcement':
      Component = AnnouncementModal;
      break;
    case 'roadmap':
      Component = RoadmapItemModal;
      break;
    case 'merchantRankingList':
    case 'btcVoucherRankingList':
    case 'taskRewardRankingList':
      Component = ScoreModal;
      break;
    case 'monthlyBTCVoucherEarnings':
      Component = ScoreModalExtended;
      break;
    default:
      Component = null;
  }

  return (
    Component && (
      <Mutation mutation={MODAL_TOGGLE}>
        {mutate => (
          <Component
            {...modal}
            onClose={() => mutate({ variables: { name: '' } })}
          />
        )}
      </Mutation>
    )
  );
};
