/*
 * Announcements Messages
 *
 * This contains all the strings for the Announcements component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  // Announcements title
  title: 'Announcements',
  // Load more button text
  showMore: 'Show more news',
  // Back button text
  back: '← All articles',
});
