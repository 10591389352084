import { compose, withHandlers } from 'recompose';

import { withRouter } from 'react-router';

import InfoPage from './InfoPage';

export default compose(
  withRouter,
  withHandlers(() => ({
    onClose: () => () => {
      const originUrl = window.location.origin;

      if (document.referrer.indexOf(originUrl) !== -1) {
        window.close();
      }

      window.open(originUrl, '_self');
    },
  })),
)(InfoPage);
