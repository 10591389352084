/*
 * Messages
 *
 * This contains all messages for Announcements.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  // message for inactive bookmark icon
  bookmark: 'Add to bookmarks',
  // message for active bookmark icon
  bookmarkActive: 'Bookmark added',
  // message for pinned icon
  pinned: 'Pinned',
});
