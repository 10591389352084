import React from 'react';
import RCTooltip from 'rc-tooltip';
import { isMobile } from 'react-device-detect';

import 'rc-tooltip/assets/bootstrap_white.css';

export const Tooltip = ({ children, container, device, ...rest }) => (
  <RCTooltip
    mouseEnterDelay={0}
    mouseLeaveDelay={0.1}
    destroyTooltipOnHide
    getTooltipContainer={() => document.querySelector(container)}
    trigger={[isMobile ? 'click' : 'hover']}
    {...rest}
  >
    {children}
  </RCTooltip>
);

Tooltip.defaultProps = {
  container: 'body',
};

export default Tooltip;
