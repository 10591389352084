import * as R from 'ramda';

import { HttpService } from '~/services';
import { renameKeys } from '~/helpers';

export const resolvers = {
  Query: {
    roadmapList: async (_, __, { cache }) => {
      // Get list of roadmap items
      const {
        data: { RoadMapList },
      } = await HttpService.client.get('/Information/GrcLanding/RoadMap');

      let roadmapList = R.map(
        R.pipe(
          renameKeys({
            Id: 'id',
            Title: 'title',
            Content: 'content',
          }),
          R.assoc('__typename', 'RoadMapListItem'),
        ),
        RoadMapList,
      );

      cache.writeData({ data: { roadmapList } });

      return roadmapList;
    },
  },
};
