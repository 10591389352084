import React from 'react';
import styled from 'styled-components/macro';

import Spinner from '~/components/Spinner';

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  justify-content: space-around;
`;

/**
 * Loader React-component
 * @memberof components
 * @param {Object} props - properties
 * @example <caption>Simple usage</caption>
 * <Loader />
 * @returns {React.Node<*>} React-component
 */
const Loader = () => (
  <LoaderWrapper>
    <Spinner />
  </LoaderWrapper>
);

export default Loader;
