import * as R from 'ramda';

export const getUserId = token => {
  const payload = getTokenPayload(token) || {};

  const userId = R.either(R.prop('sub'), R.prop('id'))(payload);

  return userId;
};

export const isTokenExpired = token => {
  const expirationDate = getExpirationDate(token);

  return expirationDate.valueOf() <= new Date().valueOf();
};

export const getExpirationDate = token => {
  const payload = getTokenPayload(token) || {};
  const date = new Date(0);

  const exp = R.prop('exp', payload);

  if (exp) {
    date.setUTCSeconds(exp);
  }

  return date;
};

export const getTokenPayload = (token = '') => {
  if (token && token.split('.').length === 3) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      return JSON.parse(
        decodeURIComponent(encodeURIComponent(window.atob(base64))),
      );
    } catch (e) {
      return undefined;
    }
  }
};
