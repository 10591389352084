import ApolloClient, { InMemoryCache } from 'apollo-boost';

import { mergeDeepRightAll } from '~/helpers';
import * as settingsState from '~/database/settings';
import * as userState from '~/database/user';
import * as videoState from '~/database/video';
import * as helperMessageState from '~/database/helperMessage';
import * as modalsState from '~/database/modals';
import * as announcementsState from '~/database/announcements';
import * as roadmapState from '~/database/roadmap';

const cache = new InMemoryCache();
export const client = new ApolloClient({
  cache,
  clientState: {
    ...mergeDeepRightAll(
      settingsState,
      userState,
      videoState,
      helperMessageState,
      modalsState,
      announcementsState,
      roadmapState,
    ),
  },
});
