import React, { Fragment } from 'react';
import styled, { css } from 'styled-components/macro';
import { ifProp, ifNotProp, prop } from 'styled-tools';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';
import messages from './messages';

import Loader from '~/components/Loader';

import Bookmark from '~/components/Bookmark';
import { prepareVideoURI } from '~/helpers';
import announcementPlaceholder from '@/images/announcement.jpg';
// $FlowIssue
import { ReactComponent as IconPinned } from '@/icons/ic-pin.svg';

const mediaContentStyles = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const Player = styled.iframe.attrs({
  frameBorder: 0,
  title: 'Video Player',
})`
  ${mediaContentStyles};
  z-index: 999;
`;

const AnnouncementImage = styled.div`
  ${mediaContentStyles};
  background: url(${prop('src')}) 50% 50% no-repeat #000;
  background-size: ${ifProp('isPlaceholder', 'cover', 'contain')};
`;

const AnnouncementWrapper = styled.div.attrs({
  className: 'react-announcement',
})`
  display: flex;
  text-decoration: none;
  border: 1px solid transparent;
  overflow: hidden;
  ${ifProp(
    'pinned',
    css`
      background: rgba(93, 198, 246, 0.1);
    `,
  )}
  
  ${ifProp(
    'bookmarked',
    css`
      border-color: #5dc6f6;
    `,
  )}

  ${ifNotProp(
    'preview',
    css`
      flex-direction: column;
    `,
  )};
`;

const AnnouncementMediaWrapper = styled.div`
  display: flex;
  position: relative;
  ${ifProp(
    'preview',
    css`
      margin-right: 20px;
      width: 246px;
      height: 164px;
      pointer-events: none;
    `,
    css`
      margin-bottom: 32px;
      width: 100%;
      padding-top: 50%;
    `,
  )};
`;

const AnnouncementMediaLoaderWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AnnouncementDate = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #c7c7c7;
  line-height: 1.29;
`;

const AnnouncementTitle = styled.h3`
  margin: 8px 0 0 !important;
  color: #fff;
`;

const AnnouncementContent = styled.div`
  margin-top: 11px;
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  line-height: 1.5;

  ${ifProp(
    'preview',
    css`
      position: relative;
      margin-right: -1em;
      padding-right: 1em;
      text-align: justify;
      max-height: ${isMobile ? '72px' : '96px'};
      overflow: hidden;

      &::before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0;
      }

      &::after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: #072d5d;
      }
    `,
  )};

  em {
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  ul,
  ol {
    padding-inline-start: 40px;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-padding-start: 40px;
    list-style-position: inside;

    ul {
      list-style-type: circle;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;

const AnnouncementInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 18px;
`;

const StyledIconPinned = styled(IconPinned)`
  margin-top: 0;
  color: #5dc6f6;
  ${ifProp(
    'preview',
    css`
      margin: 0 18px 0 18px;
    `,
    css`
      margin: 0 10px 0 10px;
    `,
  )}
`;

const StyledBookmark = styled(Bookmark)`
  margin-top: 0;
  color: #5dc6f6;
  ${ifProp(
    'preview',
    css`
      margin-right: 18px;
    `,
    css`
      margin: 0 0 0 10px;
    `,
  )}
`;

const IconContainer = styled.span`
  display: flex;
  align-items: center;
  padding-right: ${ifProp('isPreview', '20px', '0')};
  cursor: pointer;
  color: #5dc6f6;
`;

const Announcement = ({
  id,
  media,
  date,
  title,
  content,
  description,
  preview,
  withOutTitle,
  fullWidth,
  pinned,
  bookmarked,
  onAddAnnouncementBookmark,
  onDeleteAnnouncementBookmark,
  ...rest
}) => {
  const videoURI = prepareVideoURI(media);
  let mediaLink = media;
  const isPlaceholder = !mediaLink && preview;

  if (isPlaceholder) {
    mediaLink = announcementPlaceholder;
  }

  const isPreview = !(isMobile && !fullWidth) && preview;

  return (
    <AnnouncementWrapper
      preview={isPreview}
      pinned={isPreview && pinned}
      bookmarked={isPreview && bookmarked}
      {...rest}
    >
      {mediaLink && (
        <AnnouncementMediaWrapper preview={isPreview}>
          {videoURI ? (
            <Fragment>
              <AnnouncementMediaLoaderWrapper>
                <Loader />
              </AnnouncementMediaLoaderWrapper>
              <Player src={videoURI} />
            </Fragment>
          ) : (
            <AnnouncementImage src={mediaLink} isPlaceholder={isPlaceholder} />
          )}
        </AnnouncementMediaWrapper>
      )}
      <AnnouncementInformationWrapper>
        <AnnouncementDate>
          <FormattedDate
            value={date}
            year="numeric"
            month="short"
            day="numeric"
          />
          {pinned ? (
            <IconContainer>
              <StyledIconPinned preview={isPreview} />
              {!isPreview && <FormattedMessage {...messages.pinned} />}
            </IconContainer>
          ) : (
            <IconContainer
              isPreview={isPreview}
              onClick={e => e.preventDefault()}
            >
              <StyledBookmark
                id={id}
                active={bookmarked}
                text={
                  !isPreview &&
                  (bookmarked ? (
                    <FormattedMessage {...messages.bookmarkActive} />
                  ) : (
                    <FormattedMessage {...messages.bookmark} />
                  ))
                }
              />
            </IconContainer>
          )}
        </AnnouncementDate>
        {!withOutTitle && <AnnouncementTitle>{title}</AnnouncementTitle>}
        <AnnouncementContent
          dangerouslySetInnerHTML={{
            __html: description || content,
          }}
          preview={preview}
        />
      </AnnouncementInformationWrapper>
    </AnnouncementWrapper>
  );
};

export default Announcement;
