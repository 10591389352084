import { MODAL } from '~/gql';

export const resolvers = {
  Query: {
    modal: async (_, __, { cache }) => {
      const modal = {
        name: '',
        message: '',
        __typename: 'Modal',
      };

      cache.writeData({ data: { modal } });

      return modal;
    },
  },
  Mutation: {
    modalMutation: async (_, { name, message = '' }, { cache }) => {
      const { modal } = cache.readQuery({ query: MODAL });

      cache.writeData({ data: { modal: { ...modal, name, message } } });

      return { code: 0, message: 'Done', __typename: 'MessagePayload' };
    },
  },
};
