import { compose, withPropsOnChange, mapProps, pure } from 'recompose';
import * as R from 'ramda';

import RoadmapItemModal from './RoadmapItemModal';

export default compose(
  withPropsOnChange(['message'], ({ message }) => {
    const roadmapItem = R.tryCatch(JSON.parse, R.F)(message);

    if (!roadmapItem) {
      return {};
    }

    return roadmapItem;
  }),
  mapProps(R.omit(['message'])),
  pure,
)(RoadmapItemModal);
