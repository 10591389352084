import { graphql, compose } from 'react-apollo';
import { lifecycle, withStateHandlers, withProps } from 'recompose';
import localforage from 'localforage';

import { withSettings } from '~/features/App/Settings';
import { LOGGED_IN_USER } from '~/gql';
import { goToPlatform, prepareUrl, DONT_SHOW_DIALOG_KEY } from '~/helpers';

import ClientAppsModal from './ClientAppsModal';

const withLoggedInUser = graphql(LOGGED_IN_USER, {
  alias: 'withLoggedInUser',
  props: ({ data }) => data,
});

const updateDiaologVisibility = dontShowDialog => {
  localforage.setItem(DONT_SHOW_DIALOG_KEY, JSON.stringify(dontShowDialog));
};

export default compose(
  withSettings,
  withLoggedInUser,
  withStateHandlers(
    { dontShowDialog: false, isLoading: true },
    {
      toggleShowDialog: ({ dontShowDialog }) => dialog => {
        let dialogSettings = {
          ...dontShowDialog,
          ...dialog,
        };
        if (dontShowDialog) {
          updateDiaologVisibility(dialogSettings);
        }

        return {
          isLoading: false,
          dontShowDialog: dialogSettings,
        };
      },
      updateLocalForage: ({ dontShowDialog }) => () => {
        updateDiaologVisibility(dontShowDialog);
      },
    },
  ),
  withProps(
    ({ settings: { locale }, updateLocalForage, message, onClose }) => ({
      getUrl: () => {
        updateLocalForage();

        return prepareUrl(locale)(message);
      },
      goTo: newTab => {
        updateLocalForage();

        setTimeout(onClose, 500);

        return goToPlatform(locale)(message)(newTab);
      },
    }),
  ),
  lifecycle({
    async componentWillMount() {
      const { props } = this;
      const value = await localforage.getItem(DONT_SHOW_DIALOG_KEY);

      let storedDialogs;

      try {
        storedDialogs = JSON.parse(value);
      } catch (e) {}

      storedDialogs = storedDialogs || {};
      props.toggleShowDialog(storedDialogs);

      if (storedDialogs[props.message]) {
        window.open(props.getUrl(), '_blank');
      }
    },
  }),
)(ClientAppsModal);
