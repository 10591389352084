import localforage from 'localforage';
import * as R from 'ramda';

import { HttpService } from '~/services';
import { LOCALE_KEY } from '~/helpers';
import { SETTINGS } from '~/gql';

const getAcceptLanguageFromLocale = R.pipe(
  R.prop('locale'),
  R.cond([
    [R.equals('en'), R.always('en-US')],
    [R.equals('zh'), R.always('zh-CN')],
    [R.T, R.identity],
  ]),
);

const setTitle = locale => {
  document.title = locale === 'en' ? 'Global Rewards City' : '共瑞城';
};

const setManifest = locale => {
  const manifest = document.querySelector('link[rel=manifest]');

  if (manifest) {
    manifest.setAttribute(
      'href',
      `/manifest${locale === 'zh-CN' ? '.zh' : ''}.json`,
    );
  }
};

export const resolvers = {
  Query: {
    settings: async (_, __, { cache }) => {
      let locale = await localforage.getItem(LOCALE_KEY);

      const settings = {
        version: process.env.REACT_APP_VERSION || '0.0.0-dev',
        locale: locale || 'en',
        __typename: 'Settings',
      };

      HttpService.setAcceptLanguage(getAcceptLanguageFromLocale(settings));
      setTitle(settings.locale);
      setManifest(settings.locale);

      cache.writeData({ data: { settings } });

      return settings;
    },
  },
  Mutation: {
    changeLocaleMutation: async (_, { locale, refresh }, { cache }) => {
      await localforage.setItem(LOCALE_KEY, locale);

      if (refresh) {
        // Refresh page to get correct information from BE
        setTimeout(() => window.location.reload(), 50);
      } else {
        // Update 'Accept-Language' header
        HttpService.setAcceptLanguage(getAcceptLanguageFromLocale({ locale }));

        const { settings } = cache.readQuery({ query: SETTINGS });

        cache.writeData({
          data: {
            settings: {
              ...settings,
              locale,
            },
          },
        });
      }

      return { code: 0, message: 'Done', __typename: 'MessagePayload' };
    },
  },
};
