import { compose, branch, renderNothing } from 'recompose';
import { graphql } from 'react-apollo';

import { SETTINGS } from '~/gql';

export const withSettings = graphql(SETTINGS, {
  alias: 'withSettings',
  options: { fetchPolicy: 'network-only' },
  props: ({ data }) => data,
});

export const Settings = compose(
  withSettings,
  branch(({ children }) => !children, renderNothing),
)(({ children, ...rest }) => children(rest));
