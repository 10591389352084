/*
 * Bookmark
 * @module features/Information/Bookmark
 * @flow weak
 */

import { Bookmark as BMC } from './Bookmark';
import { compose, withHandlers } from 'recompose';
import { graphql } from 'react-apollo';
import { ADD_ANNOUNCEMENT_BOOKMARK, DELETE_ANNOUNCEMENT_BOOKMARK } from '~/gql';

const withAddAnnouncementBookmark = graphql(ADD_ANNOUNCEMENT_BOOKMARK, {
  name: 'addAnnouncementBookmark',
});
const withDeleteAnnouncementBookmark = graphql(DELETE_ANNOUNCEMENT_BOOKMARK, {
  name: 'deleteAnnouncementBookmark',
});

const enhance = compose(
  withAddAnnouncementBookmark,
  withDeleteAnnouncementBookmark,
  withHandlers({
    onAddAnnouncementBookmark: ({ addAnnouncementBookmark, id }) => () => {
      addAnnouncementBookmark({
        variables: { id },
      });
    },
    onDeleteAnnouncementBookmark: ({
      deleteAnnouncementBookmark,
      id,
    }) => () => {
      deleteAnnouncementBookmark({
        variables: { id },
      });
    },
  }),
);

export default enhance(BMC);
