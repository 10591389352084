import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

import { ReactComponent as LogoIcon } from '@/icons/logo.svg';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledSpinnerContainer = styled.div`
  position: relative;
  display: flex;

  align-items: center;
  justify-content: space-around;

  width: 110px;
  height: 110px;
`;

const StyledLoaderCircle = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
`;

const StyledLoaderLineMask = styled.div`
  position: absolute;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transform-origin: 55px 55px;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  animation: ${rotate} 1.2s infinite linear;
`;

const StyledLoaderLine = styled.div`
  width: 200%;
  height: 100%;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
`;

const Spinner = () => (
  <StyledSpinnerContainer>
    <StyledLoaderCircle>
      <StyledLoaderLineMask>
        <StyledLoaderLine />
      </StyledLoaderLineMask>
    </StyledLoaderCircle>
    <LogoIcon />
  </StyledSpinnerContainer>
);

export default Spinner;
