export const LOCALES = [
  { value: 'en', label: 'English' },
  { value: 'zh-CN', label: '中文' },
];

export const STORAGE_PREFIX = 'MSL:';
export const AUTH_TOKENS_KEY = `${STORAGE_PREFIX}authTokens`;
export const DONT_SHOW_DIALOG_KEY = `${STORAGE_PREFIX}dontShowDialog`;
export const LOCALE_KEY = `${STORAGE_PREFIX}locale`;
export const REFERRER_ID_KEY = `${STORAGE_PREFIX}referrerId`;
export const TUTORIAL_KEY = `${STORAGE_PREFIX}tutorial`;
