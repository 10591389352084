import React from 'react';
import { FormattedNumber } from 'react-intl';
import * as R from 'ramda';

import Ellipsis from '~/components/Ellipsis';

export const DataValue = ({
  percents,
  value,
  width,
  isEmptyVisible,
  ...rest
}) => {
  if (R.isNil(value) || Number.isNaN(value)) {
    return isEmptyVisible ? 0 : null;
  }

  return (
    <Ellipsis width={width}>
      <FormattedNumber
        minimumFractionDigits={0}
        maximumFractionDigits={percents ? 2 : 6}
        value={value}
        {...rest}
      />
    </Ellipsis>
  );
};

export default DataValue;
