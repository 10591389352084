import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import debounce from 'debounce';

import ScalableView from './Scalable';

class Scalable extends PureComponent {
  state = {
    factor: 1,
  };

  getIframe = ref => (this.iframe = ref);

  componentDidMount() {
    this.iframe.contentWindow.onresize = () => {
      const { clientWidth, clientHeight } = this.iframe;
      this.handleResize(clientWidth, clientHeight);
    };

    const { clientWidth, clientHeight } = this.iframe;
    this.handleResize(clientWidth, clientHeight);
  }

  handleResize = debounce((width, height) => {
    const {
      maxScale,
      minScale,
      originalWidth,
      originalHeight,
      byWidth,
      cover,
      contain,
    } = this.props;

    const factorWidth =
      Math.max(minScale, Math.min(maxScale, width / originalWidth)) || 1;

    const factorHeight =
      Math.max(minScale, Math.min(maxScale, height / originalHeight)) || 1;

    let minFactor = Math.min(factorWidth, factorHeight);
    let newHeight = originalHeight;
    let newWidth = originalWidth;
    let newLeft = 0;
    let aspect = width / height;
    let originalAspect = originalWidth / originalHeight;
    let imageHeight;
    let topOffset;

    if (byWidth) {
      minFactor = factorWidth;
      newHeight = (factorHeight / factorWidth) * originalHeight;
    } else if (cover) {
      if (aspect < originalAspect) {
        minFactor = factorHeight;
        newLeft = (originalWidth * minFactor - width) / 2;
      } else {
        minFactor = factorWidth;
      }
    } else if (contain) {
      originalAspect = originalWidth / originalHeight;
      imageHeight = window.innerWidth / originalAspect;

      if (aspect > originalAspect) {
        minFactor = factorHeight;
        newHeight = window.innerHeight;
      } else {
        newLeft = (originalWidth * minFactor - window.innerWidth) / 2;
      }
    }

    topOffset =
      contain && aspect < originalAspect
        ? (newHeight * minFactor) / 2 + (window.innerHeight - imageHeight) / 2
        : (newHeight * minFactor) / 2 || 0;

    this.setState({
      top: topOffset,
      left: newLeft,
      factor: minFactor,
      width: newWidth,
      height: newHeight,
      initialized: true,
    });
  }, 200);

  render() {
    return (
      <ScalableView
        {...this.props}
        {...this.state}
        getIframe={this.getIframe}
      />
    );
  }
}

Scalable.propTypes = {
  byWidth: PropTypes.bool,
  cover: PropTypes.bool,
  contain: PropTypes.bool,
  maxScale: PropTypes.number.isRequired,
  minScale: PropTypes.number.isRequired,
  originalWidth: PropTypes.number.isRequired,
  originalHeight: PropTypes.number.isRequired,
};

Scalable.defaultProps = {
  maxScale: 2,
  minScale: 0.5,
  originalWidth: 1024,
  originalHeight: 768,
};

export default Scalable;
