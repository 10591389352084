/*
 * Roadmap messages
 *
 * This contains all the strings for the Roadmap component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  // Close button text
  closeBtn: 'Close',
});
