import React, { Fragment } from 'react';
import styled, { css } from 'styled-components/macro';
import { switchProp } from 'styled-tools';
import { FormattedMessage } from 'react-intl';
import * as R from 'ramda';

import Scalable from '~/components/Scalable';
import Loader from '~/components/Loader';
import Form from '~/components/Form';
import RoadmapItem from '~/components/RoadmapItem';
import { ReactComponent as IconClose } from '@/icons/ic_x.svg';
import roadmapFg from '@/images/game_roadmap_map.png';
import roadmapBg from '@/images/game_roadmap_bg.png';

import messages from './messages';

const StyledMain = styled.div`
  position: relative;
  flex: 1;
  min-width: '320px';
  min-height: '568px';
  width: 100vw;
  background: linear-gradient(#8db994, #529960);
`;

const Background = styled.div`
  position: absolute;
  background: url(${roadmapBg}) repeat;
  width: 10000px;
  left: -5000px;
  top: -5000px;
  height: 10000px;
`;

const StyledRoadmapBackground = styled.div`
  position: relative;
  width: 1753px;
`;

const StyledRoadmap = styled.div`
  position: relative;
  left: 50%;
  margin-left: -876px;
  width: 1753px;
  height: 988px;
  background: url(${roadmapFg}) 50% 50% no-repeat transparent;
`;

const mapIndexed = R.addIndex(R.map);

const RoadmapMarker = styled.div`
  position: absolute;
  top: calc(100% + 3px);
  left: 50%;
  width: 2px;
  height: 56px;
  background: #3e9bc7;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin: -4px 0 0 -4px;
    width: 8px;
    height: 8px;
    background-color: inherit;
    border-radius: 50%;
  }
`;

const RoadmapItemWrapper = styled(Form)`
  position: absolute;

  ${switchProp('index', {
    0: css`
      right: 168px;
      bottom: 125px;
    `,
    1: css`
      right: 592px;
      bottom: 296px;

      ${/* sc-selector */ RoadmapMarker} {
        left: 264px;
      }
    `,
    2: css`
      left: 330px;
      bottom: 83px;

      ${/* sc-selector */ RoadmapMarker} {
        bottom: 91px;
        top: auto;
        left: calc(100% + 3px);
        transform-origin: 50% 0;
        transform: rotate(-90deg);
      }
    `,
    3: css`
      left: 10px;
      top: 539px;

      ${/* sc-selector */ RoadmapMarker} {
        top: 145px;
        left: calc(100% + 3px);
        transform-origin: 50% 0;
        transform: rotate(-90deg);
      }
    `,
    4: css`
      left: 92px;
      bottom: 511px;

      ${/* sc-selector */ RoadmapMarker} {
        left: calc(100% + 2px);
        transform-origin: 50% 0;
        transform: rotate(-45deg);
        height: 45px;
      }
    `,
    5: css`
      left: 324px;
      bottom: 780px;
    `,
  })};
`;

const RoadmapCloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 14px;
  line-height: 16px;
  flex-direction: column;
  color: #5dc6f6;
  cursor: pointer;

  svg {
    margin-bottom: 9px;
    width: 20px;
    height: 20px;
  }
`;

const RoadmapCloseButtonShadow = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 88px;
  opacity: 0.8;
  background-image: linear-gradient(to bottom, #06162a, rgba(6, 22, 42, 0));
`;

const renderRoadmapItem = R.memoizeWith(
  R.identity,
  modal => ({ id, ...item }, k) => {
    const roadmapItem = R.tryCatch(JSON.stringify, R.F)(item);
    const onClick = roadmapItem
      ? () => modal({ variables: { name: 'roadmap', message: roadmapItem } })
      : null;

    return (
      <RoadmapItemWrapper key={`roadmapItem__${id}-${k}`} index={k}>
        <RoadmapItem {...item} onClick={onClick} preview />
        <RoadmapMarker />
      </RoadmapItemWrapper>
    );
  },
);

const Roadmap = ({ loading, roadmapList, modal }) => {
  const renderRoadmapItemWithModal = renderRoadmapItem(modal);

  return (
    <Fragment>
      <StyledMain>
        <Scalable
          minScale={0.1}
          originalWidth={1753}
          originalHeight={988}
          contain
        >
          <Background />
          <StyledRoadmapBackground>
            <StyledRoadmap>
              {loading ? (
                <Loader />
              ) : (
                mapIndexed(renderRoadmapItemWithModal, roadmapList || [])
              )}
            </StyledRoadmap>
          </StyledRoadmapBackground>
        </Scalable>
      </StyledMain>
      <RoadmapCloseButtonShadow />
      <RoadmapCloseButton onClick={window.close}>
        <IconClose />
        <FormattedMessage {...messages.closeBtn} />
      </RoadmapCloseButton>
    </Fragment>
  );
};

export default Roadmap;
