import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import RoadmapItem from '~/components/RoadmapItem';
import Modal from '~/components/Modal';
import Form from '~/components/Form';

const MobileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  min-height: calc(100vh - 90px);
`;

const FormWrapper = styled.div`
  width: ${isMobile ? '100%' : '480px'};

  & > div {
    width: 100%;
  }
`;

const FormContent = styled.div`
  margin: 5px 10px;
  text-align: left;

  & > div {
    pointer-events: none;
  }
`;

const RoadmapItemModal = ({ onClose, ...rest }) => {
  const Wrapper = ({ CloseModal }) => {
    return (
      <Modal onClose={isMobile && onClose} isBlurred>
        <MobileContainer>
          <FormWrapper>
            <Form>
              <CloseModal />
              <FormContent>
                <RoadmapItem {...rest} />
              </FormContent>
            </Form>
          </FormWrapper>
        </MobileContainer>
      </Modal>
    );
  };

  return (
    <Modal onClose={isMobile && onClose} isBlurred>
      <Wrapper />
    </Modal>
  );
};

export default RoadmapItemModal;
