import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import InfoPage from '~/components/InfoPage';

import messages from './messages';

const StyledTitle = styled.h2`
  display: flex;
  justify-content: space-between;

  a {
    text-decoration: none;
    color: inherit;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
      cursor: default;
    }
  }
`;

const StyledFAQContent = styled.div`
  img {
    width: 100%;
  }
`;

const Faq = ({ paths, loading }) => (
  <InfoPage>
    <StyledTitle>
      {/* <Link to="/faq">
        <FormattedMessage {...messages.faq} />
      </Link> */}
      <Link to="/rules">
        <FormattedMessage {...messages.rules} />
      </Link>
    </StyledTitle>
    <StyledFAQContent>
      {!loading &&
        paths.map((item, i) => <img src={item.default} alt={i} key={i} />)}
    </StyledFAQContent>
  </InfoPage>
);

export default Faq;
