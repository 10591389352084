/*
 * DataProvider
 * @module features/App
 */

import React, { cloneElement } from 'react';
import { ApolloProvider } from 'react-apollo';

import { ApolloService } from '~/services';

/**
 * DataProvider React-component
 * @memberof features/App
 * @param {Object} props - properties
 * @param {React.Children} [props.children] - childs
 * @example <caption>Simple usage</caption>
 * <DataProvider><div /></DataProvider>
 * @returns {React.Node<*>} React-component
 */
export const DataProvider = ({ children, ...rest }) => (
  <ApolloProvider client={ApolloService.client}>
    {cloneElement(children, rest)}
  </ApolloProvider>
);
