/*
 * InfoPage messages
 *
 * This contains all the strings for the InfoPage component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  // Close button text
  closeBtn: 'Close',
});
