import React, { Fragment } from 'react';
import styled from 'styled-components/macro';
import Truncate from 'react-truncate';
import EventListener from 'react-event-listener';

import Tooltip from '~/components/Tooltip';
// $FlowIssue
import { ReactComponent as IconMore } from '@/icons/ic-more.svg';

const EllipsisWrapper = styled.div`
  display: inline-flex;
  position: relative;

  span {
    display: inline-flex;
    align-items: center;
  }
`;

const EllipsisDots = styled.div`
  display: inline-flex;
  padding-left: 4px;
  width: 20px;
  height: 10px;
  z-index: 1;
`;

const ELLIPSIS_DOTS_TOOLTIP_ALIGN = {
  offset: [14, -10],
};

export const Ellipsis = ({ children, id, width, fixed, setRef, getWidth }) => (
  <Fragment>
    <EllipsisWrapper testid="ellipsis" id={id} ref={setRef}>
      <Truncate
        width={width}
        ellipsis={
          <Tooltip
            placement="topRight"
            overlay={children}
            container={fixed && id ? `[id="${id}"]` : undefined}
            align={ELLIPSIS_DOTS_TOOLTIP_ALIGN}
          >
            <EllipsisDots>
              <IconMore />
            </EllipsisDots>
          </Tooltip>
        }
      >
        {children}
      </Truncate>
    </EllipsisWrapper>
    <EventListener target="window" onResize={getWidth} />
  </Fragment>
);

Ellipsis.defaultProps = {
  width: 60,
};

export default Ellipsis;
