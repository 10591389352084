/*
 * Auth
 * @module features/App
 */

import { compose, withPropsOnChange } from 'recompose';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { parse } from 'query-string';
import * as R from 'ramda';

import { LOGGED_IN_USER, SAVE_REFERRER_ID, UPDATE_LOCALE } from '~/gql';
import { LOCALES } from '~/helpers/constants';

import { Auth as ATHC } from './Auth';

const withSaveReferrerIdMutation = graphql(SAVE_REFERRER_ID, {
  name: 'saveReferrerId',
  options: { fetchPolicy: 'no-cache' },
});

const withLoggedInUser = graphql(LOGGED_IN_USER, {
  alias: 'withLoggedInUser',
  options: { fetchPolicy: 'network-only' },
  props: ({ data }) => data,
});

const withLocaleMutation = graphql(UPDATE_LOCALE, {
  name: 'updateLocale',
});

const enhance = compose(
  withRouter,
  withLoggedInUser,
  withLocaleMutation,
  withSaveReferrerIdMutation,
  withPropsOnChange(
    ['location'],
    async ({ history, location, updateLocale, saveReferrerId }) => {
      const { search } = location;
      let { locale, referrer_id: referrerId } = parse(search);

      if (locale) {
        const mappedLocale = R.cond([
          [R.equals('en-US'), R.always('en')],
          [R.equals('zh'), R.always('zh-CN')],
          [R.T, R.identity],
        ])(locale);

        if (LOCALES.find(({ value }) => value === mappedLocale)) {
          updateLocale({ variables: { locale: mappedLocale } });
        }
      }

      if (referrerId) {
        saveReferrerId({ variables: { values: { referrerId } } });
      }

      if (search) {
        history.replace({ ...location, search: null });
      }

      return {};
    },
  ),
);

export const Auth = enhance(ATHC);
