import { compose, pure, withProps, lifecycle } from 'recompose';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router';
import * as R from 'ramda';

import { ANNOUNCEMENT, ANNOUNCEMENTS_LIST } from '~/gql';

import Announcements from './Announcements';
import { AceService } from '~/services';

const getId = R.path(['match', 'params', 'id']);
const announcementOptions = R.pipe(
  getId,
  // eslint-disable-next-line no-underscore-dangle
  R.assocPath(['variables', 'id'], R.__, {}),
  R.assoc('fetchPolicy', 'cache-and-network'),
);

const withAnnouncement = graphql(ANNOUNCEMENT, {
  alias: 'withAnnouncement',
  options: announcementOptions,
  props: R.prop('data'),
  skip: R.complement(getId),
});
const withAnnouncementsList = graphql(ANNOUNCEMENTS_LIST, {
  alias: 'withAnnouncementsList',
  options: {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: { offset: 0, limit: 10 },
  },
  props: R.prop('data'),
  skip: getId,
});

const enhance = compose(
  withRouter,
  withAnnouncement,
  withAnnouncementsList,
  withProps(props => ({
    id: getId(props),
  })),
  pure,
  lifecycle({
    async componentDidMount() {
      const tokens = await AceService.getTokens();
      if (R.isEmpty(tokens)) {
        await AceService.signIn();
      }
    },
  }),
);

export default enhance(Announcements);
