import React from 'react';
import styled from 'styled-components/macro';
import { ifProp } from 'styled-tools';

const ScalableWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: ${ifProp('cover', 'auto', 'hidden')};
`;

const ScalableFrame = styled.iframe.attrs({
  title: 'title',
})`
  position: absolute;
  width: 100%;
  height: 100%;
  visibility: hidden;
  z-index: -1;
`;

const ScalableContent = styled.div`
  position: relative;
  left: 50%;
`;

const Scalable = ({
  className,
  children,
  width,
  height,
  factor,
  top,
  left,
  initialized,
  getIframe,
  cover,
}) => (
  <ScalableWrapper cover={cover} className={className}>
    <ScalableFrame ref={getIframe} />
    <ScalableContent
      style={{
        left: `calc(50% + ${left}px)`,
        top,
        width,
        height,
        transform: `translate(-50%, -50%) scale(${factor}, ${factor})`,
      }}
    >
      {initialized ? children : null}
    </ScalableContent>
  </ScalableWrapper>
);

export default Scalable;
