/*
 * ErrorBoundaryReporter
 * @module features/App
 */

import React from 'react';
import Raven from 'raven-js';

export class ErrorBoundaryReporter extends React.Component {
  componentDidCatch(error, extra) {
    // Capture exception to Raven
    Raven.captureException(error, { extra });
  }

  render() {
    return this.props.children;
  }
}
