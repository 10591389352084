import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import * as R from 'ramda';

import Spinner from '~/components/Spinner';
import InfoPage from '~/components/InfoPage';
import Announcement from '~/components/Announcement';

import messages from './messages';

const StyledTitle = styled.h2`
  text-align: center;
`;

const AnnouncementsListWrapper = styled.div`
  position: relative;

  .react-announcement + .react-announcement {
    margin-top: 40px;
  }
`;

const LoadMoreButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const LoadMoreButton = styled.div`
  display: inline-flex;
  padding: 0 33px;
  border: 1px solid currentColor;
  box-shadow: 0 10px 25px 0 rgba(37, 15, 139, 0.1);
  font-size: 16px;
  font-weight: 500;
  color: #5dc6f6;
  line-height: 47px;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    color: #fff;
  }
`;

const BackButton = styled(LoadMoreButton)`
  padding: 0;
  border: none;
  box-shadow: none;
  text-decoration: none;
`;

const AnnouncementsLoader = styled.div`
  display: flex;
  justify-content: center;
`;

const Announcements = ({
  announcement,
  announcementsList,
  loading,
  fetchMore,
  id,
}) => {
  const items = R.pipe(R.propOr([], 'items'))(announcementsList);

  const isAnnouncement = R.complement(R.isNil)(announcement) || id;

  const pinnedItems = items.filter(({ pinned }) => pinned);
  const bookmarkedItems = items.filter(
    ({ bookmarked, pinned }) => bookmarked && !pinned,
  );
  const regularItems = items.filter(
    ({ bookmarked, pinned }) => !bookmarked && !pinned,
  );

  let showMoreButton = false;

  if (!isAnnouncement) {
    showMoreButton = R.pipe(
      R.prop('total'),
      R.lt(items.length),
    )(announcementsList);
  }

  const title = (isAnnouncement && R.prop('title', announcement)) || (
    <FormattedMessage {...messages.title} />
  );

  return (
    <InfoPage>
      {isAnnouncement && !loading ? (
        <Fragment>
          <StyledTitle>{title}</StyledTitle>
          <Announcement {...announcement} withOutTitle />
          <BackButton as={Link} to="/announcements">
            <FormattedMessage {...messages.back} />
          </BackButton>
        </Fragment>
      ) : (
        <Fragment>
          {isAnnouncement ? null : (
            <Fragment>
              <StyledTitle>{title}</StyledTitle>
              <AnnouncementsListWrapper>
                {R.map(
                  v => (
                    <Announcement
                      {...v}
                      key={`announcement__${v.id}`}
                      as={Link}
                      to={`/announcements/${v.id}`}
                      preview
                      pinned
                    />
                  ),
                  pinnedItems,
                )}
                {R.map(
                  v => (
                    <Announcement
                      {...v}
                      key={`announcement__${v.id}`}
                      as={Link}
                      to={`/announcements/${v.id}`}
                      preview
                      bookmarked
                    />
                  ),
                  bookmarkedItems,
                )}
                {R.map(
                  v => (
                    <Announcement
                      {...v}
                      key={`announcement__${v.id}`}
                      as={Link}
                      to={`/announcements/${v.id}`}
                      preview
                    />
                  ),
                  regularItems,
                )}
                {!loading && showMoreButton && (
                  <LoadMoreButtonWrapper>
                    <LoadMoreButton
                      onClick={() =>
                        fetchMore({
                          variables: { offset: items.length },
                          updateQuery: (prev, { fetchMoreResult }) => {
                            if (!fetchMoreResult) {
                              return prev;
                            }

                            const pathToItems = R.lensPath([
                              'announcementsList',
                              'items',
                            ]);
                            const newItems = [
                              ...R.path(['announcementsList', 'items'], prev),
                              ...R.path(
                                ['announcementsList', 'items'],
                                fetchMoreResult,
                              ),
                            ];
                            const result = R.set(pathToItems, newItems, prev);

                            return result;
                          },
                        })
                      }
                    >
                      <FormattedMessage {...messages.showMore} />
                    </LoadMoreButton>
                  </LoadMoreButtonWrapper>
                )}
              </AnnouncementsListWrapper>
            </Fragment>
          )}
          {loading && (
            <AnnouncementsLoader>
              <Spinner />
            </AnnouncementsLoader>
          )}
        </Fragment>
      )}
    </InfoPage>
  );
};

export default Announcements;
