import localforage from 'localforage';
import * as R from 'ramda';
import * as AceSdk from '@ace-auth/sdk-browser';

import { renameKeys, AUTH_TOKENS_KEY, LOCALE_KEY } from '~/helpers';

const getLocale = async () => {
  const locale = await localforage.getItem(LOCALE_KEY);

  return locale
    ? R.cond([[R.equals('en'), R.always('en-US')], [R.T, R.identity]])(locale)
    : 'en-US';
};

const AceService = new AceSdk.CreateClient({
  aceEndpoint: process.env.REACT_APP_ACE_ENDPOINT || '',
  ownAceEndpoint: process.env.REACT_APP_ACE_SSO_ENDPOINT || '',
  clientId: process.env.REACT_APP_CLIENT_ID || '',
  defaultRedirectUri: window.location.origin,
  storage: localforage,
  storageKey: AUTH_TOKENS_KEY,
  autorefresh: true,
});

export const getTokens = async () => {
  try {
    const tokens = await AceService.getTokens();

    return tokens
      ? R.pipe(
          renameKeys({
            id_token: 'idToken',
            access_token: 'accessToken',
            refresh_token: 'refreshToken',
          }),
        )(tokens)
      : {};
  } catch (error) {
    return {};
  }
};

export const signIn = async () => {
  AceService.setLocale(await getLocale());

  const authTokens = await AceService.signIn();

  if (authTokens) {
    window.location.reload();
  }
};

export const signUp = async referrer => {
  AceService.setLocale(await getLocale());
  await AceService.signUp(referrer ? { referrer } : null);
};

export const signOut = async () => {
  await localforage.removeItem(AUTH_TOKENS_KEY);
  await AceService.signOut();
};
