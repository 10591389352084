import React from 'react';
import styled, { css } from 'styled-components/macro';
import { isMobile } from 'react-device-detect';
import { ifProp } from 'styled-tools';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

const RoadmapItemWrapper = styled.div.attrs({
  className: 'react-roadmapItem',
})`
  padding: 5px 10px 2px 20px;
  ${ifProp(
    'preview',
    css`
      width: 315px;
    `,
  )};
  min-height: 136px;
  box-sizing: border-box;
  text-align: left;
`;

const RoadmapItemTitle = styled.h3`
  margin: 2px 0 0 !important;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
`;

const RoadmapItemContent = styled.p`
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  margin-top: 11px;
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  text-align: justify;

  ${ifProp(
    'preview',
    css`
      height: 60px;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      line-height: 1.25;
    `,
    css`
      line-height: 1.5;
    `,
  )};

  em {
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }

  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;

    li {
      display: flex;

      &::before {
        content: '';
        position: relative;
        margin: 5px 5px 0 0;
        width: 7px;
        height: 7px;
        background-color: #58b5e0;
        border-radius: 50%;
      }
    }
  }

  ol {
    list-style-position: inside;
    list-style-type: decimal;
  }
`;

const RoadmapLink = styled.div`
  margin-top: 11px;
  font-size: 14px;
  line-height: 20px;
  color: #5dc6f6;
  cursor: pointer;
`;

const RoadmapItem = ({ title, content, onClick, preview }) => (
  <RoadmapItemWrapper preview={preview} onClick={isMobile ? onClick : null}>
    <RoadmapItemTitle>{title}</RoadmapItemTitle>
    <RoadmapItemContent
      dangerouslySetInnerHTML={{ __html: content }}
      preview={preview}
    />
    {onClick && (
      <RoadmapLink onClick={!isMobile ? onClick : null}>
        <FormattedMessage {...messages.more} />
      </RoadmapLink>
    )}
  </RoadmapItemWrapper>
);

export default RoadmapItem;
