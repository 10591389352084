import * as R from 'ramda';
import localforage from 'localforage';

import { AceService, HttpService, TokenService } from '~/services';
import { LOGGED_IN_USER } from '~/gql';
import {
  renameKeys,
  mergeDeepRightAll,
  REFERRER_ID_KEY,
  LOCALE_KEY,
} from '~/helpers';

const checkExpiredToken = value =>
  TokenService.isTokenExpired(value) ? null : value;

const prepareRankingList = (prop, __typename) => ({ rankingList, myRank }) =>
  R.pipe(
    R.propOr([], 'Ranking'),
    R.cond([
      [
        list => R.gt(+myRank.Rank, list.length) || isNaN(myRank.Rank),
        list => {
          const isNext = +myRank.Rank - list.length < 2;
          const emptyData = { CityPeopleName: '', OrderQuantity: '', Rank: '' };

          return R.pipe(
            isNext ? R.dropLast(2) : R.identity,
            R.append(emptyData),
            R.append(myRank),
          )(list);
        },
      ],
      [R.T, R.identity],
    ]),
    R.map(item => ({
      people: item.CityPeopleName,
      quantity: item.OrderQuantity,
      ranking: item.Rank,
      myScore: R.equals(item, myRank),
      __typename,
    })),
    R.applySpec({ [prop]: R.identity }),
  )(rankingList);

// const preparePreviousWinnerList = (list, __typename) =>
//   R.pipe(
//     R.map(item => ({
//       voucherMonthValue: item.BitcoinVoucherMonthValue,
//       rewardMonthValue: item.MbtcRewardAmount,
//       merchantName: item.MerchantName,
//       platformName: item.PlatformName,
//       ranking: item.Ranking,
//       __typename,
//     })),
//   )(list);

// const prepareCurrentWinnerList = (list, __typename) =>
//   R.pipe(
//     R.map(item => ({
//       monthValue: item.BitcoinVoucherMonthValue,
//       merchantName: item.MerchantName,
//       platformName: item.PlatformName,
//       ranking: item.Ranking,
//       __typename,
//     })),
//   )(list);

let previousRequestCancelToken;

export const resolvers = {
  Query: {
    loggedInUser: async (_, __, { cache }) => {
      const authTokens = R.pipe(
        R.map(checkExpiredToken),
        R.reject(value => !value),
      )(await AceService.getTokens());

      const loggedInUser = R.isEmpty(authTokens)
        ? null
        : {
            ...authTokens,
            // Try to get user ID from token
            id: R.pipe(
              R.prop('idToken'),
              TokenService.getUserId,
            )(authTokens),
            __typename: 'LoggedInUser',
          };

      if (!loggedInUser) {
        // Remove access token from headers
        HttpService.removeAuthorizationToken();
      } else {
        // Add access token to headers
        HttpService.setAuthorizationToken(R.prop('accessToken', loggedInUser));

        let oldLoggedInUser = {};

        try {
          const cachedLoggedInUser = cache.readQuery({
            query: LOGGED_IN_USER,
            variables: { includeIdToken: false },
          });

          oldLoggedInUser = R.prop('loggedInUser', cachedLoggedInUser);
        } catch (error) {}

        if (
          R.prop('accessToken', oldLoggedInUser) !==
          R.prop('accessToken', loggedInUser)
        ) {
          if (previousRequestCancelToken) {
            previousRequestCancelToken();
          }

          await HttpService.client.post(
            '/Account/Login',
            {},
            {
              cancelToken: new HttpService.CancelToken(cancelToken => {
                previousRequestCancelToken = cancelToken;
              }),
            },
          );
        }
      }

      cache.writeData({ data: { loggedInUser } });

      return loggedInUser;
    },
    referrerId: async (_, __, { cache }) => {
      const id = (await localforage.getItem(REFERRER_ID_KEY)) || '';
      const referrerId = {
        id,
        __typename: 'ReferrerId',
      };

      cache.writeData({ data: { referrerId } });

      return referrerId;
    },
    info: async (_, __, { cache }) => {
      const locale = await localforage.getItem(LOCALE_KEY);
      const RANKING_LISTS_PARAMS = {
        params: { PageSize: 30, PageNum: 1 },
      };

      // Get necessary information
      const [
        {
          data: { LandingInfo },
        },
        {
          data: { LandingInfo: LandingInfoTaskReward },
        },
        {
          data: { LandingInfo: LandingInfoBtcVoucher },
        },
        // {
        //   data: { LandingInfo: LandingInfoMerchantRanking },
        // },
        // {
        //   data: { BonusFundRank: LandingBonusFundRanking },
        // },
        {
          data: { MyWealth },
        },
        {
          data: { Invitation },
        },
        {
          data: { MyProfile },
        },
      ] = await Promise.all([
        HttpService.client.get('/Information/GRC/GetLandingInfo'),
        HttpService.client.get(
          '/Information/GRC/GetTaskRewardRanking',
          RANKING_LISTS_PARAMS,
        ),
        HttpService.client.get(
          '/Information/GRC/GetBtcVoucherRanking',
          RANKING_LISTS_PARAMS,
        ),
        // HttpService.client.get(
        //   '/Information/GRC/GetMerchantRanking',
        //   RANKING_LISTS_PARAMS,
        // ),
        // HttpService.client.get(
        //   '/Information/Grc/GetBonusFundRanking',
        //   RANKING_LISTS_PARAMS,
        // ),
        HttpService.client.get('/MyWealth/GetMyWealth'),
        HttpService.client.get('/MyNetwork/InvitationQr'),
        HttpService.client.get('/Profile/GetMyProfile'),
      ]);

      const info = {
        // NOTE: mocks for case if in the future it will be necessary to return
        // /Information/GRC/GetMerchantRanking and /Information/Grc/GetBonusFundRanking data
        // if not then it is necessary to remove appropriate score modals components
        currentWinnersList: [],
        merchantRankingList: [],
        previousWinnersList: [],
        ...mergeDeepRightAll(
          R.pipe(
            R.pick(['MyTeamPopulation', 'MyTotalTeamBonus']),
            renameKeys({
              MyTeamPopulation: 'myTeamPopulation',
              MyTotalTeamBonus: 'myTotalTeamBonus',
            }),
          )(LandingInfo),
          R.pipe(
            R.pick(['TopTaskReward', 'MyTaskRewardRanking']),
            renameKeys({
              TopTaskReward: 'rankingList',
              MyTaskRewardRanking: 'myRank',
            }),

            prepareRankingList(
              'taskRewardRankingList',
              'TopTaskRewardRankingItem',
            ),
          )(LandingInfoTaskReward),
          R.pipe(
            R.pick(['TopBtcVoucher', 'MyBtcVoucherRanking']),
            renameKeys({
              TopBtcVoucher: 'rankingList',
              MyBtcVoucherRanking: 'myRank',
            }),
            prepareRankingList(
              'btcVoucherRankingList',
              'TopBtcVoucherRankingItem',
            ),
          )(LandingInfoBtcVoucher),
          // R.pipe(
          //   R.pathOr([], ['TopMerchant', 'Ranking']),
          //   R.map(item => ({
          //     amount: item.BtcAmount,
          //     platformName: item.PlatformName,
          //     ranking: item.Rank,
          //     __typename: 'TopMerchantRankingItem',
          //   })),
          //   R.applySpec({ merchantRankingList: R.identity }),
          // )(LandingInfoMerchantRanking),
          // R.pipe(
          //   R.pick(['CurrentMonthWinners', 'PreviousMonthWinners']),
          //   renameKeys({
          //     CurrentMonthWinners: 'currentWinners',
          //     PreviousMonthWinners: 'previousWinners',
          //   }),
          //   data => ({
          //     currentWinnersList: prepareCurrentWinnerList(
          //       data.currentWinners,
          //       'CurrentMonthWinnersItem',
          //     ),
          //     previousWinnersList: preparePreviousWinnerList(
          //       data.previousWinners,
          //       'PreviousMonthWinnersItem',
          //     ),
          //   }),
          // )(LandingBonusFundRanking),
          R.pipe(
            x =>
              locale === 'zh-CN'
                ? {
                    ...x,
                    CityScorePercentage: parseFloat(x.CityScorePercentage2),
                  }
                : x,
            R.pick([
              'AvailableBalance',
              'CityScorePercentage',
              'TotalRewardEarned',
            ]),
            renameKeys({
              AvailableBalance: 'balance',
              CityScorePercentage: 'cityScore',
              TotalRewardEarned: 'totalReward',
            }),
          )(MyWealth),
          renameKeys({
            URL: 'referralLink',
          })(Invitation),
          R.pipe(
            R.pick(['UserGuid', 'Username', 'UserRole']),
            renameKeys({
              UserGuid: 'guid',
              Username: 'username',
              UserRole: 'role',
            }),
          )(MyProfile),
          { __typename: 'Info' },
        ),
      };

      cache.writeData({ data: { info } });

      return info;
    },
    populationInfo: async (_, __, { cache }) => {
      // Get necessary information
      const {
        data: { GetPopulationTeamInfo },
      } = await HttpService.client.get(
        '/Information/GRC/GetPopulationTeamInfo',
      );

      const populationInfo = {
        ...mergeDeepRightAll(
          renameKeys({
            GrcPopulation: 'grcPopulation',
            GrcTotalBonus: 'grcTotalTeamBonus',
          })(GetPopulationTeamInfo),
          { __typename: 'PopulationInfo' },
        ),
      };

      cache.writeData({ data: { populationInfo } });

      return populationInfo;
    },
  },
  Mutation: {
    saveReferrerIdMutation: async (_, { values }) => {
      const referrerId = R.propOr(null, 'referrerId', values);

      if (referrerId) {
        await localforage.setItem(REFERRER_ID_KEY, referrerId);
      }

      return {
        code: 0,
        message: 'Done',
        __typename: 'MessagePayload',
      };
    },
  },
};
