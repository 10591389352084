import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import ScrollBar from 'react-scrollbar';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as IconClose } from '@/icons/ic_x.svg';

import messages from './messages';

const ContentWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #072d5d;
  color: #fff;
  z-index: 1000;
  padding-top: 68px;

  .scrollarea {
    height: 100%;
  }
`;

const ContentBody = styled.div`
  font-size: 17px;
  line-height: 1.3;
  font-weight: 300;
  padding: 0 10px 20px;
  overflow: hidden;
  max-width: 855px;
  margin: 0 auto;

  h2 {
    margin: 0 0 31px;
    font-size: 42px;
    line-height: 49px;
    font-weight: normal;
  }

  h3 {
    font-size: 24px;
    margin: 32px 0 11px;
  }

  b {
    font-weight: 600;
  }

  p,
  i,
  li,
  th,
  td {
    line-height: 1.3;
  }

  i {
    display: inline-block;
    font-size: 15px;
    font-style: italic;

    & + i {
      margin-top: 5px;
    }
  }

  a {
    color: #5dc6f6;
  }

  p,
  ul {
    margin-bottom: 5px;
  }

  ul {
    list-style: disc;
    list-style-position: inside;

    ul {
      margin: 15px 0 15px 25px;
      list-style: circle;
      list-style-position: inside;
    }
  }

  li {
    & + li {
      margin-top: 10px;
    }
  }

  table {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }

  th,
  td {
    padding: 5px;
    border: 1px solid #fff;
  }

  th {
    background-color: #fff;
    color: #072d5d;
    font-weight: 600;
  }

  .react-citizen-rules-date {
    margin-top: 40px;
    text-align: right;
  }

  @media only screen and (max-width: 800px) {
    h2 {
      font-size: 24px;
      line-height: 28px;
    }

    h3 {
      font-size: 20px;
    }
  }
`;

const Close = styled.div`
  position: fixed;
  right: 60px;
  top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 16px;
  color: #5dc6f6;
  cursor: pointer;

  & > svg {
    margin-bottom: 9px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    color: #fff;
  }

  @media only screen and (max-width: 800px) {
    right: 20px;
    top: 20px;
  }
`;

/**
 * InfoPage React-component
 * @memberof components
 * @param {Object} props - properties
 * @param {React.Children} [props.children] - children
 * @param {Function} [props.onClose] - close handler
 * @example <caption>Simple usage</caption>
 * <InfoPage />
 * @returns {React.Node<*>} React-component
 */
const InfoPage = ({ children, onClose }) => (
  <ContentWrapper>
    <ScrollBar>
      <ContentBody>{children}</ContentBody>
    </ScrollBar>
    <Close onClick={onClose}>
      <IconClose />
      <FormattedMessage {...messages.closeBtn} />
    </Close>
  </ContentWrapper>
);

InfoPage.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
};

export default InfoPage;
