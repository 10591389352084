import React from 'react';
import styled from 'styled-components/macro';

// $FlowIssue
import { ReactComponent as IconBookmarked } from '@/icons/ic-bookmark-active.svg';
// $FlowIssue
import { ReactComponent as IconBookmark } from '@/icons/ic-bookmark.svg';

const BookmarkIconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const BookmarkText = styled.div`
  margin-left: 10px;
  font-size: 14px;
  line-height: 23px;
  color: #5dc6f6;
`;

export const Bookmark = ({
  active,
  text,
  onAddAnnouncementBookmark,
  onDeleteAnnouncementBookmark,
  ...rest
}) => {
  return (
    <BookmarkIconContainer
      {...rest}
      onClick={
        active ? onDeleteAnnouncementBookmark : onAddAnnouncementBookmark
      }
    >
      {active ? <IconBookmarked /> : <IconBookmark />}
      {text && <BookmarkText>{text}</BookmarkText>}
    </BookmarkIconContainer>
  );
};
