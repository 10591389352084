/*
 * AppProvider
 * @module features/App
 */

import { compose, branch, renderNothing, pure } from 'recompose';
import * as R from 'ramda';

import { withSettings } from '~/features/App/Settings';

import { AppProvider as APC } from './AppProvider';

const enhance = compose(
  withSettings,
  branch(R.prop('loading'), renderNothing),
  pure,
);

export const AppProvider = enhance(APC);
