import {
  compose,
  mapProps,
  withHandlers,
  withStateHandlers,
  withPropsOnChange,
} from 'recompose';
import { injectIntl } from 'react-intl';
import * as R from 'ramda';

import Faq from './Faq';

const padStart = (str, padCount, padString) => {
  let newStr = str;

  for (let i = 0; i < padCount - str.length; i++) {
    newStr = padString + newStr;
  }

  return newStr;
};

export default compose(
  injectIntl,
  mapProps(({ match, intl, ...rest }) => ({
    ...rest,
    type: R.path(['params', 'type'], match),
    locale: R.prop('locale', intl),
  })),
  withStateHandlers(
    {
      loading: true,
      paths: [],
    },
    {
      setLoading: () => () => ({ loading: true, paths: [] }),
      setImages: () => paths => ({ loading: false, paths }),
    },
  ),
  withHandlers({
    getImages: ({ locale, type, history, setImages }) => async () => {
      const isZh = locale === 'zh-CN';

      let ext = 'png';
      let size = isZh ? 32 : 20;

      if (type === 'rules') {
        ext = 'jpg';
        size = isZh ? 17 : 17;
      }

      const promises = R.range(1, size).map(item =>
        import(/* webpackMode: "eager" */ `../../../assets/${type}/${
          isZh ? 'zh' : 'en'
        }/${padStart(item.toString(), 3, '0')}.${ext}`),
      );

      try {
        const paths = await Promise.all(promises);

        setImages(paths);
      } catch (error) {
        history.replace('/');
      }
    },
  }),
  withPropsOnChange(['locale', 'type'], ({ setLoading, getImages }) => {
    setLoading();
    getImages();

    return {};
  }),
)(Faq);
