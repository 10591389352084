import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import InfoPage from '~/components/InfoPage';

import messages from './messages';

const MerchantRules = () => (
  <InfoPage>
    <FormattedMessage {...messages.title} tagName="h2" />
    <FormattedHTMLMessage {...messages.description} tagName="div" />
  </InfoPage>
);

export default MerchantRules;
