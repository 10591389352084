import { HELPER_MESSAGE } from '~/gql';

export const resolvers = {
  Query: {
    helperMessage: async (_, __, { cache }) => {
      const helperMessage = {
        messageName: '',
        __typename: 'HelperMessage',
      };

      cache.writeData({ data: { helperMessage } });

      return helperMessage;
    },
  },
  Mutation: {
    helperMessageMutation: async (_, { messageName }, { cache }) => {
      const { helperMessage } = cache.readQuery({ query: HELPER_MESSAGE });

      cache.writeData({
        data: {
          helperMessage: {
            ...helperMessage,
            messageName,
          },
        },
      });

      return {
        code: 0,
        message: 'Done',
        __typename: 'MessagePayload',
      };
    },
  },
};
