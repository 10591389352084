/*
 * RoadmapItem Messages
 *
 * This contains all the strings for the RoadmapItem component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  // More button text
  more: 'More',
});
